@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-switch {
  background-color: rgba(0, 161, 230, 0.2); }

.ant-switch-checked {
  background-color: #00a1e6; }

.table-row-action-container {
  display: flex;
  align-items: center;
  color: #00a1e6; }
  .table-row-action-container .action {
    display: flex;
    align-items: center;
    cursor: pointer; }
