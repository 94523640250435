@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.proxai-manage-continer .header-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 27px;
  color: black; }

.proxai-manage-continer .nowarp-elips {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px; }

.proxai-manage-continer .theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.proxai-manage-continer .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6; }
  .proxai-manage-continer .theme-normal-btn:hover, .proxai-manage-continer .theme-normal-btn:active, .proxai-manage-continer .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.proxai-manage-continer .header-action-container {
  display: flex;
  justify-content: flex-end;
  padding: 14px 0px 17px 0px; }

.proxai-manage-continer .project-row-action {
  color: #00a1e6;
  display: flex;
  align-items: center; }

.proxai-manage-continer .project-message-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;
  color: black;
  margin-top: 18px; }

.proxai-manage-continer .dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #00a1e6;
  margin-right: 7px; }
