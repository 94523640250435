@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.scientific-manage-continer .nowarp-elips {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px; }

.scientific-manage-continer .header-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 27px;
  color: black; }

.scientific-manage-continer .add-project {
  margin-top: 51px;
  margin-left: 24px;
  width: 302px;
  height: 104px;
  border: 1px dashed #d6d6d6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.64);
  border-radius: 16px;
  cursor: pointer; }

.scientific-manage-continer .add-project-icon {
  font-size: 24px;
  margin-bottom: 8px; }

.scientific-manage-continer .dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #00a1e6;
  margin-right: 7px; }

.scientific-manage-continer .project-list-title {
  margin-left: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 24px; }

.scientific-manage-continer .ant-table-thead > tr > th {
  background: white;
  border-top: 1px solid #e8e8e8; }

.scientific-manage-continer .ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid #e8e8e8; }

.scientific-manage-continer .ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid #e8e8e8; }

.scientific-manage-continer .project-row-action {
  color: #00a1e6;
  display: flex;
  align-items: center; }

.scientific-manage-continer .pro-row-btn {
  border-left: 1px solid #d6d6d6;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer; }

.scientific-manage-continer .pro-row-btn-disable {
  border-left: 1px solid #d6d6d6;
  padding-left: 16px;
  padding-right: 16px;
  color: rgba(0, 161, 230, 0.3);
  cursor: not-allowed; }

.scientific-manage-continer .pro-row-btn-first {
  padding-right: 10px;
  cursor: pointer; }

.scientific-manage-continer .table-row-action-container {
  display: flex;
  align-items: center;
  color: #00a1e6; }
  .scientific-manage-continer .table-row-action-container .action {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    border-left: 1px solid #d6d6d6; }
  .scientific-manage-continer .table-row-action-container .firstAction {
    border-left: none;
    border-right: 1px solid #d6d6d6;
    padding-right: 10px; }
  .scientific-manage-continer .table-row-action-container .noleft {
    border-left: none; }
