.myDatas {
  .myDataCard {
    .ant-card-body {
      padding-left: 0px;
      padding-top: 0px;
    }
  }

  .myDataAmountTitle {
    width: 80px;
    text-align: right;
  }

  .myDataAmount {
    text-align: right;
    padding-right: 50px !important;
  }

  .myDataOpt {
    height: 100%;

    padding: {
      top: 0;
      bottom: 0;
    }

    // text-align: center;

    .myDataOptBtn {
      height: 54px;
      line-height: 54px;
      width: 80px;
      float: left;
      text-align: center;

      a {
        padding: 16px 8px;
      }
    }

    .btnAera {
      padding: 5px 11.5px;
      border-left: 1px #d6d6d6 solid;
    }

    .myDataOptBtn:hover {
      a {
        color: #ffffff;
        background: #00a1e6;
      }

      .btnAera {
        border-left-color: #00a1e6;
      }
    }
  }

  .table-header {
    padding-bottom: 16px;

    padding-left: 24px;
    padding-top: 16px;
    background-color: white;
    margin-top: 10px;
    border: 1px solid #eff2f5;
    // opacity: 0.67;
  }
  .ant-table-thead > tr > th {
    background-color: white;
  }
  .ant-table-thead > tr {
    border: 1px solid rgba(214, 214, 214, 0.64);
    border-top: none;
  }
}
