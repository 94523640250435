$main-color: #00a1e6;
.tool-card-container {
  border-radius: 16px;
  border: 1px solid rgba(214, 214, 214, 1);
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 104px;

  &:hover {
    border: 1px solid #00a1e6 !important;
  }
}

.isDisable .card-title,
.isDisable .card-discription {
  color: #d6d6d6;
}

.card-disable {
  color: #a62024;
  font-size: 12px;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #d6d6d6;
}

.gray {
  color: #d6d6d6;
}

.tool-card-container-on {
  border-radius: 16px;
  border: 1px solid #00a1e6;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 104px;
}

.tool-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tool-card-icon {
  height: 31px;
  width: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  color: #00a1e6;
  font-size: 14px;
  font-weight: bold;
  padding-left: 17px;
}

.card-discription {
  color: rgba(0, 0, 0, 0.36);
  font-size: 12px;
  padding-left: 48px;
}
.card-action {
  color: #00a1e6;
  font-size: 12px;
  border-bottom: 1px solid $main-color;
}
