.data-manager-container {
  padding-top: 51px;
}
.ant-table-thead > tr {
  border-bottom: 1px solid rgba($line-color, 0.32);
}

.ant-table-thead > tr > th {
  background-color: white;
  color: rgba(#000, 0.85);
}

.ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid rgba($line-color, 0.32);
}

.ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid rgba($line-color, 0.32);
}

@mixin common-steps {
  .ant-steps-item-process .ant-steps-item-icon {
    background: $main-color;
    border-color: $main-color;
  }

  .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: $main-color;
    font-weight: normal;
  }

  .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $main-color;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: $main-color;
  }

  .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    border-color: rgba(#000, 0.64);
  }

  .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(#000, 0.64);
    border-color: rgba(0, 0, 0, 0.64);
  }

  .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.64);
  }
}

.data-manager-container {
  @include common-steps;
  padding-top: 51px;
  .ant-table-thead > tr {
    border-bottom: 1px solid rgba(214, 214, 214, 0.32);
  }

  .ant-table-thead > tr > th {
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-left: 1px solid rgba(214, 214, 214, 0.32);
  }

  .ant-table-thead > tr:first-child > th:last-child {
    border-right: 1px solid rgba(214, 214, 214, 0.32);
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $main-color;
    border-color: $main-color;
  }

  // .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  //   color: $main-color !important;
  // }
  .ant-radio-button-wrapper:hover {
    color: $main-color;
  }

  .list-type-header {
    .ant-radio-button-wrapper-checked:hover {
      color: #fff !important;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-input:focus {
    box-shadow: none;
  }

  .ant-radio-inner::after {
    left: 3px;
  }
}

.hover_show1 {
  display: flex;
  align-items: center;
}

.hover_cor {
  transition: all 1s;

  .hover_show_all {
    display: none;
  }

  .hover_show {
    display: flex;
    align-items: center;
  }

  &:hover {
    .hover_show {
      display: none;
    }

    .hover_show_all {
      display: flex;
      border: 1px solid $main-color;
      border-radius: 16px;

      .exp_box {
        display: flex;
        align-items: center;
        padding: 5px 13px;
      }

      .tit_b {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .tit_r {
          width: 16px;
          height: 16px;
          border-radius: 16px;
          border: 1px solid $main-color;
          margin-right: 5px;
        }

        .tit_n {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 500;
          color: $main-color;
        }
      }
    }
  }
}

.tools-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
}

.radio-container {
  border: 1px solid rgba($line-color, 0.64);
  border-radius: 23px;
}

.export {
  margin-left: 8px;
  color: $main-color;
  cursor: pointer;
}

.disable {
  color: $main-color !important;
}

.list-type-header {
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    border: none;
    font-weight: bold;
  }

  .ant-radio-button-wrapper {
    border: none;
    border-radius: 16px;
    outline: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: white !important;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ffffff;
  }
}

.list-type-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  border: 1px solid rgba($line-color, 0.64);
}

.list-type-center-btn {
  flex: 1;
  height: 32px;
  color: $main-color;
}

.list-type-left-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-type-right-container {
  flex: 1px;
  display: flex;
  align-items: center;
}

.share-steps-container {
  padding-top: 0px;

  width: 700px;
}

.project-input {
  border: none;
  padding-left: 16px;
  padding-right: 24px;
  border-bottom: 1px solid rgba($line-color, 0.64);
}

.genetic-from-select {
  width: 156px;
}

.genetic-from-select-container {
  display: 'flex';
  margin-top: '26px';
  align-items: center;
}

/* 上箭头 */
.jiantou-before {
  display: block;
  content: '';
  border-width: 8px 8px 8px 8px;
  border-style: solid;
  border-color: transparent transparent #ff4d4d transparent;
  width: 10px;
  /* 定位 */
}

.show-top-border {
  border-top: 1px solid rgba($line-color, 0.64);
}

.sequences-uuid-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.top {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 150px;
  top: -15px;
  z-index: 2;
}

.top-image {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 250px;
  top: -15px;
  z-index: 2;
}

.top-arrow1,
.top-arrow2 {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  /*兼容ie8-*/
  border-top: 6px transparent dashed;
  border-left: 10px transparent dashed;
  border-right: 10px transparent dashed;
  border-bottom: 10px white solid;
  overflow: hidden;
}

.top-arrow1 {
  border-bottom: 10px rgba($line-color, 0.64) solid;
}

.top-arrow2 {
  top: 1px;
  /*重要*/
  border-bottom: 10px white solid;
}

.genetic-form-view {
  margin-top: 10px;
  display: 'flex';
  align-items: 'flex-end';
  margin-bottom: '24px';
  position: 'relative';
}

.original-form-header {
  .ant-input-affix-wrapper .ant-input {
    border-radius: 16px;
  }
}

.dm-table-action {
  color: $main-color;
  cursor: pointer;
}

.dm-table-action-disable {
  color: rgba(#000, 0.32);
  cursor: pointer;
}

.type-header-icon {
  background-color: white;
  color: $main-color;
  border-radius: 16px;
  border-color: $main-color;
  border-width: 1px;
}

.type-header-icon:hover {
  background-color: $main-color;
  color: white;
  border-radius: 16px;
  border-color: $main-color;
  border-width: 1px;
}

.text-last {
  width: 75px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
}
