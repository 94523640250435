@charset "UTF-8";
/*常量区域START*/
@import url(~antd/dist/antd.css);
a {
  color: #00a1e6; }

.ant-select-selection__clear {
  color: #00a1e6; }
  .ant-select-selection__clear:hover {
    color: #00a1e6; }

/*常量区域END*/
body {
  min-width: 1200px !important;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*组件样式区域START*/
  /*组件样式区域END*/
  /*模板样式区域START*/
  /*模板样式区域END*/ }
  body #DescriptionItem .DescriptionItem {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65); }
    body #DescriptionItem .DescriptionItem .short {
      margin-right: 8px;
      color: rgba(0, 0, 0, 0.65); }
    body #DescriptionItem .DescriptionItem .long {
      margin-right: 8px;
      display: inline-block;
      color: rgba(0, 0, 0, 0.65); }
    body #DescriptionItem .DescriptionItem .dataDetailsTitle {
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px; }
    body #DescriptionItem .DescriptionItem .dataDetailsInfo {
      margin-bottom: 16px; }
  body .InfoDrawer .DrawerBtnGroup {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right; }
  body .InfoDrawer .short {
    height: 1.2rem;
    margin-bottom: 1rem; }
  body .InfoDrawer .img-group img {
    width: 100%;
    margin-bottom: 32px;
    border-radius: 12px; }
  body #LeftMenu {
    min-height: 86.17vh; }
    body #LeftMenu .LeftMenu {
      width: 234px !important;
      border-left: 1px solid #e8e8e8 !important;
      height: 100% !important; }
      body #LeftMenu .LeftMenu .headerItem {
        border: 0 !important;
        padding: 0 !important;
        margin: 0;
        height: 221px !important; }
        body #LeftMenu .LeftMenu .headerItem .headerCard {
          width: 100%;
          height: 100%;
          padding: 0;
          border: 0;
          border-right: 1px solid #e8e8e8 !important; }
          body #LeftMenu .LeftMenu .headerItem .headerCard .ant-card-body {
            width: 100%;
            padding: 0 !important;
            background-color: #fff;
            display: block;
            margin-left: auto;
            margin-right: auto; }
            body #LeftMenu .LeftMenu .headerItem .headerCard .ant-card-body .headerIcon {
              display: block;
              margin-left: auto;
              margin-right: auto; }
              body #LeftMenu .LeftMenu .headerItem .headerCard .ant-card-body .headerIcon svg {
                width: 88px;
                height: 88px; }
      body #LeftMenu .LeftMenu .icon-formenu {
        font-size: 1.2rem; }
      body #LeftMenu .LeftMenu .ant-menu-submenu-title,
      body #LeftMenu .LeftMenu .ant-menu-item {
        padding-left: 56px !important;
        display: flex;
        align-items: center; }
      body #LeftMenu .LeftMenu .ant-menu-item-selected::after {
        border-left: 1px solid #e8e8e8 !important; }
      body #LeftMenu .LeftMenu .ant-menu-item-selected:hover {
        color: #00a1e6; }
      body #LeftMenu .LeftMenu .ant-menu-item-selected {
        color: #00a1e6;
        padding-left: 6px !important; }
      body #LeftMenu .LeftMenu .ant-menu-submenu-title:hover {
        color: #00a1e6; }
      body #LeftMenu .LeftMenu .ant-menu-item-selected a {
        color: #00a1e6; }
      body #LeftMenu .LeftMenu .ant-menu-item-active {
        color: #00a1e6; }
      body #LeftMenu .LeftMenu .ant-menu-item-selected:before {
        content: '';
        width: 36px;
        height: 4px;
        display: inline-block;
        margin-right: 1em;
        margin-bottom: 5px;
        background-color: #00a1e6; }
      body #LeftMenu .LeftMenu .ant-menu-item::after {
        border-right: 1px solid #e8e8e8 !important; }
      body #LeftMenu .LeftMenu .headerItem:after {
        border-right: 1px solid #e8e8e8 !important; }
      body #LeftMenu .LeftMenu .headerItem {
        padding-left: 0 !important; }
  body .Menu {
    font-weight: bold; }
    body .Menu .Menu {
      min-width: 499px; }
      body .Menu .Menu .icon-formenu {
        font-size: 1.15em !important; }
      body .Menu .Menu .top-item {
        padding: 0 14px;
        color: red; }
        body .Menu .Menu .top-item .ant-menu-submenu-title {
          padding: 0; }
        body .Menu .Menu .top-item .top-item-line {
          height: 1px;
          width: 100px;
          background-color: #000000; }
      body .Menu .Menu ant-menu-item-selected {
        color: red; }
  body #RouteHeader .routeHeaderContainer {
    height: 96px;
    padding-left: 24px;
    background-color: '#FFFFFF'; }
  body #RouteHeader .router-header-hidden-breadcrumb {
    height: 64px;
    padding-left: 24px;
    background-color: '#FFFFFF'; }
  body #RouteHeader .router-breadcrumb-container {
    height: 32px;
    display: flex;
    align-items: center; }
    body #RouteHeader .router-breadcrumb-container .ant-breadcrumb a {
      color: rgba(0, 0, 0, 0.45); }
  body #RouteHeader .router-title-container {
    height: 64px;
    display: flex;
    align-items: center; }
  body #DataShareItem .data-share-item-container {
    height: 128px;
    margin-bottom: 24px; }
    body #DataShareItem .data-share-item-container .share-header {
      height: 48px;
      padding-left: 24px;
      padding-right: 24px;
      color: rgba(0, 0, 0, 0.65);
      display: flex;
      align-items: center;
      background-color: #fafafa; }
    body #DataShareItem .data-share-item-container .share-container {
      height: 80px;
      display: flex;
      align-items: center;
      border-left: 1px solid rgba(214, 214, 214, 0.64);
      border-right: 1px solid rgba(214, 214, 214, 0.64);
      border-bottom: 1px solid rgba(214, 214, 214, 0.64); }
  body #DataShareItem .ant-checkbox-inner {
    border-color: #00a1e6; }
  body #DataShareItem .ant-checkbox-checked {
    background-color: #00a1e6 !important;
    border-color: #00a1e6 !important; }
  body #index-layout {
    /* --------------------------------------------------------- */ }
    body #index-layout .index-layout .card-right .ant-card-body {
      padding-left: 0 !important; }
    body #index-layout .index-layout .down {
      color: #fff;
      font-size: 20px;
      margin-top: 2px; }
    body #index-layout .index-layout .more-link-list-index .more-link {
      color: #ffffff !important; }
    body #index-layout .index-layout #fullbg {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 9; }
    body #index-layout .index-layout .Menu {
      height: 64px; }
      body #index-layout .index-layout .Menu li {
        height: 100%; }
    body #index-layout .index-layout .ant-menu-item {
      line-height: 64px;
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    body #index-layout .index-layout .ant-menu-submenu {
      border-bottom: none !important; }
    body #index-layout .index-layout .ant-menu-horizontal {
      border-bottom: 0 !important; }
    body #index-layout .index-layout .menu-right-item:active {
      background-color: #fff;
      color: #00a1e6; }
    body #index-layout .index-layout .ant-menu-item-selected {
      border: none !important; }
    body #index-layout .index-layout .pholder {
      position: absolute;
      height: 64px;
      left: 16em !important;
      top: -2em; }
    body #index-layout .index-layout .standard-plus {
      padding-left: 24px !important;
      padding-right: 24px !important; }
    body #index-layout .index-layout .broCenter {
      display: flex;
      align-items: center; }
    body #index-layout .index-layout .ant-dropdown-placement-bottomRight .ant-dropdown-menu {
      margin-right: 60px !important;
      margin-top: 15px; }
    body #index-layout .index-layout .bell-group {
      padding-left: 3em;
      padding-right: 3em; }
    body #index-layout .index-layout .leftInItem {
      animation: leftIn 1s forwards; }
      body #index-layout .index-layout .leftInItem > div {
        letter-spacing: 16px;
        font-size: 18px;
        font-weight: 600; }
      body #index-layout .index-layout .leftInItem > button {
        background: transparent;
        color: rgba(255, 255, 255, 0.85);
        border-color: rgba(255, 255, 255, 0.85);
        margin-top: 20px; }
        body #index-layout .index-layout .leftInItem > button:active, body #index-layout .index-layout .leftInItem > button:hover {
          color: white;
          border-color: white; }
    body #index-layout .index-layout .index-bg {
      background: #000 url("/indexBg.svg") no-repeat center;
      background-size: cover;
      height: 600px; }
    body #index-layout .index-layout .img-row {
      height: 143px; }
      body #index-layout .index-layout .img-row img {
        margin-top: -110px;
        height: 465px;
        width: 841px;
        animation: toLong 1s forwards; }
    body #index-layout .index-layout .header-index {
      padding: 0;
      height: 64px;
      min-height: 64px;
      background: transparent;
      padding: 0 10px; }
      body #index-layout .index-layout .header-index .unreadFalse {
        color: rgba(0, 0, 0, 0.45); }
      body #index-layout .index-layout .header-index .unreadTrue {
        color: black; }
      body #index-layout .index-layout .header-index .bell-btn {
        color: #fff; }
      body #index-layout .index-layout .header-index .logo1 {
        color: #fff; }
      body #index-layout .index-layout .header-index .divideLine {
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)); }
      body #index-layout .index-layout .header-index .userHeader {
        float: right;
        margin-top: 12px;
        cursor: pointer; }
      body #index-layout .index-layout .header-index .Menu {
        background: transparent; }
        body #index-layout .index-layout .header-index .Menu .ant-menu-submenu-title,
        body #index-layout .index-layout .header-index .Menu .ant-menu-item {
          padding: 0.96vh;
          padding-left: 16px;
          padding-right: 16px; }
        body #index-layout .index-layout .header-index .Menu .ant-menu-submenu {
          padding: 0; }
        body #index-layout .index-layout .header-index .Menu > .ant-menu-item {
          color: rgba(255, 255, 255, 0.85); }
        body #index-layout .index-layout .header-index .Menu .ant-menu-item-selected,
        body #index-layout .index-layout .header-index .Menu .ant-menu-submenu-title:hover,
        body #index-layout .index-layout .header-index .Menu .ant-menu-item:hover {
          color: white; }
        body #index-layout .index-layout .header-index .Menu .ant-menu-item-selected {
          border-top: 1px solid #fff; }
        body #index-layout .index-layout .header-index .Menu .medataicon-logo1 {
          font-size: 24px; }
      body #index-layout .index-layout .header-index .bell-btn {
        font-size: 20px;
        line-height: 20px; }
      body #index-layout .index-layout .header-index sup {
        top: 5px;
        right: 46px; }
      body #index-layout .index-layout .header-index .right-top {
        width: 326px !important; }
      body #index-layout .index-layout .header-index .logo1-col {
        line-height: 27px;
        display: flex; }
        body #index-layout .index-layout .header-index .logo1-col button {
          background: transparent;
          color: #fff;
          font-size: 12px;
          height: 24px;
          width: 72px;
          padding: 0; }
        body #index-layout .index-layout .header-index .logo1-col .logo1 {
          font-size: 64px; }
          body #index-layout .index-layout .header-index .logo1-col .logo1 svg {
            width: 1.5em; }
      body #index-layout .index-layout .header-index .user-header {
        font-size: 1.85em; }
        body #index-layout .index-layout .header-index .user-header svg {
          width: 1em;
          height: 1em; }
      body #index-layout .index-layout .header-index .username {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 60px !important; }
      body #index-layout .index-layout .header-index .user {
        float: left;
        color: #fff; }
      body #index-layout .index-layout .header-index .down {
        margin-left: auto;
        margin-right: auto; }
    body #index-layout .main-layout {
      background: transparent;
      color: rgba(0, 0, 0, 0.64) !important; }
      body #index-layout .main-layout .down {
        color: #00a1e6;
        font-size: 20px;
        margin-top: 2px; }
      body #index-layout .main-layout .ant-dropdown-placement-bottomRight .ant-dropdown-menu {
        margin-right: 60px !important;
        margin-top: 15px; }
      body #index-layout .main-layout .ant-dropdown-trigger i {
        color: #00a1e6; }
      body #index-layout .main-layout .pholder {
        position: absolute;
        height: 64px;
        left: 16em !important;
        top: -2em; }
      body #index-layout .main-layout .Menu {
        height: 64px; }
        body #index-layout .main-layout .Menu li {
          height: 100%; }
      body #index-layout .main-layout .ant-menu-item {
        line-height: 64px;
        padding-top: 0 !important;
        padding-bottom: 0 !important; }
      body #index-layout .main-layout .ant-menu-submenu {
        border-bottom: none !important; }
      body #index-layout .main-layout header {
        height: 64px; }
      body #index-layout .main-layout .ant-menu-horizontal {
        border-bottom: 0 !important; }
      body #index-layout .main-layout .menu-right-item:active {
        background-color: #fff;
        color: #00a1e6; }
      body #index-layout .main-layout .ant-menu-item-selected {
        border: none !important; }
      body #index-layout .main-layout .broCenter {
        display: flex;
        align-items: center;
        /*   padding-left: 26px; */ }
      body #index-layout .main-layout #fullbg {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 9; }
      body #index-layout .main-layout .leftInItem {
        animation: leftIn 1s forwards; }
      body #index-layout .main-layout .unreadFalse {
        color: rgba(0, 0, 0, 0.45); }
      body #index-layout .main-layout .unreadTrue {
        color: black; }
      body #index-layout .main-layout .logo1 {
        color: #00a1e6;
        cursor: pointer; }
      body #index-layout .main-layout .img-row img {
        margin-top: -110px;
        height: 465px;
        animation: toLong 1s forwards; }
      body #index-layout .main-layout header {
        height: 64px;
        min-height: 64px;
        background: #ffffff;
        padding: 0 10px; }
        body #index-layout .main-layout header .down {
          color: #00a1e6 !important; }
        body #index-layout .main-layout header .ant-menu-item-selected {
          border-bottom: 0; }
        body #index-layout .main-layout header .ant-menu-item-selected,
        body #index-layout .main-layout header .ant-menu-submenu-title:hover,
        body #index-layout .main-layout header .ant-menu-item:hover {
          border-bottom: none;
          color: #00a1e6; }
        body #index-layout .main-layout header .ant-menu-item-selected,
        body #index-layout .main-layout header .ant-menu-submenu-title:active,
        body #index-layout .main-layout header .ant-menu-item:active {
          border-bottom: none;
          color: #00a1e6; }
        body #index-layout .main-layout header .divideLine {
          width: 100%;
          height: 2px;
          background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0)); }
        body #index-layout .main-layout header .userHeader {
          float: right;
          margin-top: 12px;
          cursor: pointer; }
        body #index-layout .main-layout header .Menu {
          background: transparent;
          color: #00a1e6; }
          body #index-layout .main-layout header .Menu .ant-menu-submenu-title,
          body #index-layout .main-layout header .Menu .ant-menu-item {
            padding: 0.96vh;
            padding-left: 16px;
            padding-right: 16px;
            height: 100%; }
          body #index-layout .main-layout header .Menu li {
            height: 64px;
            padding: 14px;
            padding-left: 20px;
            padding-right: 20px; }
        body #index-layout .main-layout header .bell-btn {
          font-size: 20px;
          line-height: 20px;
          color: #000; }
        body #index-layout .main-layout header sup {
          top: 5px;
          right: 46px; }
        body #index-layout .main-layout header .logo1-col {
          line-height: 27px;
          display: flex; }
          body #index-layout .main-layout header .logo1-col button {
            background: transparent;
            color: #000;
            font-size: 12px;
            height: 24px;
            width: 72px;
            padding: 0;
            border-color: #00a1e6; }
            body #index-layout .main-layout header .logo1-col button i {
              color: #00a1e6; }
          body #index-layout .main-layout header .logo1-col .logo1 {
            font-size: 64px; }
            body #index-layout .main-layout header .logo1-col .logo1 svg {
              width: 1.5em; }
        body #index-layout .main-layout header .user-header {
          font-size: 1.85em; }
          body #index-layout .main-layout header .user-header svg {
            width: 1em;
            height: 1em; }
        body #index-layout .main-layout header .username {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 60px !important; }
        body #index-layout .main-layout header .user {
          float: left;
          color: #000; }
        body #index-layout .main-layout header .user-down {
          color: #00a1e6; }
        body #index-layout .main-layout header .down {
          margin-left: auto;
          margin-right: auto;
          position: relative;
          top: 2px;
          font-size: 20px;
          color: #d6d6d6;
          margin-left: 5px; }
      body #index-layout .main-layout .more-link-list-index .more-link {
        color: #000 !important; }
      body #index-layout .main-layout .standard {
        border-bottom: 1px solid #e8e8e8;
        padding-left: 24px !important;
        padding-right: 24px !important; }

@keyframes rightIn {
  from {
    margin-left: 15em; }
  to {
    margin-left: 0; } }

@keyframes toLong {
  from {
    width: 0;
    margin-left: -15em;
    opacity: 0; }
  to {
    width: 841px;
    margin-left: 0;
    opacity: 1; } }

@keyframes leftIn {
  from {
    margin-left: -15em;
    opacity: 0; }
  to {
    margin-left: 0;
    opacity: 1; } }
    body #index-layout .index-left-ul {
      list-style-type: none;
      font-size: 14px;
      width: 22em; }
      body #index-layout .index-left-ul .index-left-li {
        margin-top: 20px;
        text-indent: -32px;
        margin-left: -8px; }
      body #index-layout .index-left-ul li:before {
        content: '■\2002\2002\2002'; }
    body #index-layout .index-right-ul {
      list-style-type: none;
      padding-left: 0;
      animation: rightIn 1s forwards; }
      body #index-layout .index-right-ul li {
        margin-top: 39px;
        float: right; }
        body #index-layout .index-right-ul li .ant-card {
          border: none;
          width: 165px;
          height: 84px;
          background: transparent;
          color: #fff;
          background: transparent url("/border.svg") no-repeat;
          background-position: center;
          background-size: 100% 100%; }
          body #index-layout .index-right-ul li .ant-card .ant-card-body {
            padding: 0;
            padding-top: 0.5em; }
            body #index-layout .index-right-ul li .ant-card .ant-card-body h3 {
              color: #fff;
              text-align: center;
              font-size: 18px;
              margin: 0; }
            body #index-layout .index-right-ul li .ant-card .ant-card-body p {
              text-align: center;
              font-size: 30px; }
    body #index-layout .fixed-menu {
      position: fixed;
      right: 0;
      top: 70vh;
      z-index: 999; }
      body #index-layout .fixed-menu .fixed-item {
        background: #f2f2f2 !important;
        height: 36px;
        width: 36px;
        color: #00a1e6;
        padding: 0;
        margin-bottom: 4px;
        margin-top: 0px; }
        body #index-layout .fixed-menu .fixed-item i {
          color: #00a1e6;
          font-size: 36px !important;
          margin: 0; }
          body #index-layout .fixed-menu .fixed-item i svg {
            width: 24px; }
      body #index-layout .fixed-menu .right-menus-active .icon-container-active i {
        color: #fff !important;
        font-size: 24px !important;
        margin: 0; }
        body #index-layout .fixed-menu .right-menus-active .icon-container-active i svg {
          width: 24px; }
      body #index-layout .fixed-menu .right-menus i,
      body #index-layout .fixed-menu .right-menus-active i {
        color: #00a1e6 !important;
        font-size: 24px !important;
        margin: 0; }
        body #index-layout .fixed-menu .right-menus i svg,
        body #index-layout .fixed-menu .right-menus-active i svg {
          width: 24px; }
      body #index-layout .fixed-menu .fixed-item:hover {
        background: #00a1e6 !important; }
        body #index-layout .fixed-menu .fixed-item:hover i {
          color: #fff !important;
          font-size: 24px !important; }
          body #index-layout .fixed-menu .fixed-item:hover i svg {
            width: 24px; }
    body #index-layout .footerCenter {
      border-left: 1px solid #dadada;
      border-right: 1px solid #dadada; }
    body #index-layout .indexMenuContent {
      height: 100%;
      min-width: 1170px;
      border-bottom: none; }
      body #index-layout .indexMenuContent .indexMenuContentNext {
        display: flex;
        align-items: center;
        width: 100%; }
        body #index-layout .indexMenuContent .indexMenuContentNext .logo1-col {
          top: -8px; }
        body #index-layout .indexMenuContent .indexMenuContentNext .sideMenu {
          margin-right: 1em; }
    @media (min-width: 1200px) {
      body #index-layout .indexMenuContent {
        width: 1170px;
        margin-right: auto;
        margin-left: auto; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      body #index-layout .indexMenuContent {
        width: 1170px;
        margin-right: auto;
        margin-left: auto; } }
    @media (min-width: 768px) and (max-width: 991px) {
      body #index-layout .indexMenuContent {
        width: 1170px;
        margin-right: auto;
        margin-left: auto; } }
    body #index-layout #mainContent {
      min-height: 86.17vh; }
    body #index-layout .down {
      margin-left: 0.2em !important;
      margin-right: 0.2em !important; }
    body #index-layout .ant-layout-footer {
      height: 7vh;
      min-height: 64px;
      padding: 0; }
    body #index-layout .arrow-up {
      z-index: 10;
      top: 6px;
      border-top-color: #fff;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: #fff;
      position: absolute;
      display: block;
      width: 8.48528137px;
      height: 8.48528137px;
      background: white;
      border-style: solid;
      border-width: 4.24264069px;
      left: 50%;
      -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
      margin: 0;
      padding: 0;
      border-top: 1px solid #d6d6d6;
      border-left: 1px solid #d6d6d6; }
    body #index-layout .head-right {
      top: 0 !important; }
    body #index-layout .user-msg {
      padding-right: 10px;
      left: 14px; }
    body #index-layout .messageListDown {
      margin-top: -3px;
      margin-left: 1px;
      background-color: #fff;
      border-radius: 4px;
      left: 200px; }
      body #index-layout .messageListDown .ant-list-item-meta {
        display: flex;
        align-items: center;
        justify-content: center; }
      body #index-layout .messageListDown .ant-tabs-top-bar {
        border-left: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        border-top: 1px solid #d9d9d9; }
      body #index-layout .messageListDown .ant-spin-nested-loading {
        max-height: 300px;
        overflow: auto; }
      body #index-layout .messageListDown .ant-list-item-meta-content h4 {
        width: 238px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      body #index-layout .messageListDown .toRead {
        cursor: pointer; }
      body #index-layout .messageListDown .ant-tabs-bar {
        margin: 0;
        border-bottom: none; }
      body #index-layout .messageListDown .ant-tabs-nav-scroll {
        display: flex;
        align-items: center;
        justify-content: center; }
    body #index-layout .messageList {
      margin-left: -0.7em;
      margin-right: 1em; }
    body #index-layout .hide-box {
      float: left;
      height: 17px;
      width: 75px; }
  body #DataSearch .dataSearch_box {
    width: 100%;
    display: flex; }
  body #DataSearch .right_none {
    flex: 1;
    justify-content: center; }
  body #DataSearch .marLfet {
    margin-left: 32px; }
  body #DataSearch .right_box_co {
    flex: 1;
    margin-left: 16px;
    overflow-y: scroll;
    height: 100vh; }
  body #DataSearch .check_fle {
    display: flex; }
  body #DataSearch .right_box {
    width: 100%; }
    body #DataSearch .right_box::-webkit-scrollbar {
      display: none;
      /*隐藏滚动条*/ }
  body #DataSearch .left_box {
    width: 280px;
    height: 100vh;
    overflow-y: scroll;
    border: 1px solid #d6d6d6;
    border-top: none; }
    body #DataSearch .left_box::-webkit-scrollbar {
      display: none;
      /*隐藏滚动条*/ }
  body #DataSearch .serch_box {
    display: flex;
    margin-top: 43px;
    align-items: center;
    justify-content: center; }
  body #DataSearch .ipt {
    width: 440px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  body #DataSearch .ser_btn {
    width: 96px;
    height: 32px;
    background: white;
    border-radius: 16px;
    border: 1px solid #00a1e6;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 600;
    color: #00a1e6;
    line-height: 32px;
    margin-left: 32px; }
  body #DataSearch .data_box {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: black;
    border: 1px solid rgba(214, 214, 214, 0.4);
    margin-top: 16px; }
    body #DataSearch .data_box:hover {
      border: 1px solid rgba(0, 161, 230, 0.56); }
    body #DataSearch .data_box .data_content {
      padding: 21px 16px;
      padding-bottom: 0; }
    body #DataSearch .data_box .data_header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    body #DataSearch .data_box .illness_name {
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 500;
      color: black; }
  body #DataSearch .white {
    background: white !important; }
  body #DataSearch .filter_box {
    width: 100%; }
    body #DataSearch .filter_box .filter_name {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.64);
      align-items: center;
      width: 276px;
      position: absolute;
      background: white;
      z-index: 100; }
      body #DataSearch .filter_box .filter_name .bor {
        width: 24px;
        height: 4px;
        background: #00a1e6;
        border-radius: 2px;
        margin: auto; }
      body #DataSearch .filter_box .filter_name .sele {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: black; }
    body #DataSearch .filter_box .filter_item {
      padding: 20px 0;
      width: 50%;
      text-align: center; }
    body #DataSearch .filter_box .filter_item_box {
      background: #fafafa; }
      body #DataSearch .filter_box .filter_item_box .filter_con {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px; }
        body #DataSearch .filter_box .filter_item_box .filter_con i {
          font-size: 20px; }
        body #DataSearch .filter_box .filter_item_box .filter_con .fi_b_t {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 500;
          color: black; }
  body #DataSearch .checko_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: black;
    margin-top: 10px;
    padding: 5px 0;
    position: relative; }
    body #DataSearch .checko_box:hover {
      background: #e3f7ff; }
    body #DataSearch .checko_box .check {
      margin-right: 8px;
      width: 14px;
      height: 14px; }
  body #DataSearch .ant-rate {
    color: #00a1e6; }
  body #DataSearch .data_detail {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px; }
    body #DataSearch .data_detail .data_detail_b {
      display: flex;
      align-items: center; }
      body #DataSearch .data_detail .data_detail_b .de_key {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.64); }
      body #DataSearch .data_detail .data_detail_b .de_val {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.64);
        margin-left: 8px;
        width: 85px; }
  body #DataSearch .popov {
    width: 210px; }
  body #DataSearch .mo_b {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 500;
    color: black;
    display: flex;
    align-items: center; }
    body #DataSearch .mo_b span {
      font-size: 24px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #00a1e6;
      padding-right: 10px; }
  body #DataSearch .da_ri {
    margin-right: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  body #DataSearch .bottom_box {
    width: 100%;
    border-top: 0.5px solid rgba(214, 214, 214, 0.36);
    margin-top: 30px; }
    body #DataSearch .bottom_box .bottom_content {
      height: 64px;
      display: flex;
      align-items: center; }
    body #DataSearch .bottom_box .icon_box {
      display: flex;
      align-items: center;
      color: #00a1e6;
      font-size: 24px;
      width: 50%;
      justify-content: center; }
      body #DataSearch .bottom_box .icon_box .icon_na {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        padding-left: 4px; }
      body #DataSearch .bottom_box .icon_box .icon_hover {
        display: flex;
        align-items: center; }
        body #DataSearch .bottom_box .icon_box .icon_hover:hover {
          background: #00a1e6;
          color: white !important;
          padding: 5px 20px;
          border-radius: 20px; }
  body #DataSearch .check_pad {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  body #DataSearch .ipt {
    background: none;
    outline: none !important; }
    body #DataSearch .ipt:focus {
      box-shadow: none; }
  body #DataSearch .rigBor {
    border-right: 1px solid rgba(214, 214, 214, 0.64); }
  body #DataSearch .popover_style {
    background: white;
    color: #000000;
    box-shadow: 0px -3px 4px 3px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 161, 230, 0.32); }
  body #DataSearch .tooltip-custom {
    background: white;
    color: black !important; }
    body #DataSearch .tooltip-custom div {
      background-color: none; }
      body #DataSearch .tooltip-custom div div:first-child {
        background: none;
        border-top-color: rgba(0, 161, 230, 0.32); }
      body #DataSearch .tooltip-custom div div:last-child {
        color: rgba(0, 0, 0, 0.64);
        background-color: white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 161, 230, 0.32); }

@keyframes rise {
  from {
    padding: 186px 0 186px; }
  to {
    padding: 121px 0 251px; } }

@keyframes toHide {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes toShow {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes toWhite {
  from {
    color: #00a1e6; }
  to {
    color: #fff; } }

@keyframes toShowWhite {
  from {
    color: #00a1e6;
    opacity: 0; }
  to {
    color: #00a1e6;
    opacity: 1; } }
  body #IndexPage .main-container {
    display: block;
    flex: auto;
    color: #00a1e6;
    flex-direction: row; }
    body #IndexPage .main-container .indexItem:nth-child(1) .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(1) .indexCard:hover .indexIcon {
        animation: toHide 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(1) .indexCard:hover .indexIconUp,
      body #IndexPage .main-container .indexItem:nth-child(1) .indexCard:hover p {
        animation: toShowWhite 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(1) .indexCard:hover h2 {
        color: #00a1e6; }
    body #IndexPage .main-container .indexItem:nth-child(2) .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(2) .indexCard:hover .indexIcon {
        animation: toHide 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(2) .indexCard:hover .indexIconUp,
      body #IndexPage .main-container .indexItem:nth-child(2) .indexCard:hover p {
        animation: toShowWhite 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(2) .indexCard:hover h2 {
        color: #00a1e6; }
    body #IndexPage .main-container .indexItem:nth-child(3) .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(3) .indexCard:hover .indexIcon {
        animation: toHide 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(3) .indexCard:hover .indexIconUp,
      body #IndexPage .main-container .indexItem:nth-child(3) .indexCard:hover p {
        animation: toShowWhite 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(3) .indexCard:hover h2 {
        color: #00a1e6; }
    body #IndexPage .main-container .indexItem:nth-child(4) .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(4) .indexCard:hover .indexIcon {
        animation: toHide 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(4) .indexCard:hover .indexIconUp,
      body #IndexPage .main-container .indexItem:nth-child(4) .indexCard:hover p {
        animation: toShowWhite 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(4) .indexCard:hover h2 {
        color: #00a1e6; }
    body #IndexPage .main-container .indexItem:nth-child(5) .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(5) .indexCard:hover .indexIcon {
        animation: toHide 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(5) .indexCard:hover .indexIconUp,
      body #IndexPage .main-container .indexItem:nth-child(5) .indexCard:hover p {
        animation: toShowWhite 0.5s forwards; }
      body #IndexPage .main-container .indexItem:nth-child(5) .indexCard:hover h2 {
        color: #00a1e6; }
    body #IndexPage .main-container .indexItem {
      width: 20%; }
      body #IndexPage .main-container .indexItem .ant-card-bordered {
        border: none; }
      body #IndexPage .main-container .indexItem .indexCard {
        width: 100%;
        height: 560px; }
        body #IndexPage .main-container .indexItem .indexCard .ant-card-body {
          width: 100%;
          padding: 0; }
          body #IndexPage .main-container .indexItem .indexCard .ant-card-body .indexIcon,
          body #IndexPage .main-container .indexItem .indexCard .ant-card-body .indexIconUp {
            font-size: 96px;
            margin-bottom: 68px;
            color: #fff; }
          body #IndexPage .main-container .indexItem .indexCard .ant-card-body .indexIconUp {
            opacity: 0;
            margin-left: -96px; }
      body #IndexPage .main-container .indexItem h2 {
        text-align: center;
        color: #00a1e6; }
      body #IndexPage .main-container .indexItem p {
        opacity: 0;
        margin-bottom: 0.5em; }
    body #IndexPage .main-container .main-standard {
      padding-left: 24px !important;
      padding-right: 24px !important; }
  body #IndexPage .itemContent {
    padding-left: 1em;
    padding-right: 1em;
    height: 0; }
  body #DowloadList .sleOr,
  body #downLoadDrawer .sleOr {
    width: 276px;
    height: 32px;
    margin-left: 20px; }
  body #DowloadList .Table,
  body #downLoadDrawer .Table {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid #eff2f5; }
  body #DowloadList .detailShow,
  body #downLoadDrawer .detailShow {
    margin-right: 10px;
    color: #00a1e6;
    font-size: 16px;
    cursor: pointer; }
  body #DowloadList .constainer,
  body #downLoadDrawer .constainer {
    height: 100%;
    width: 100%; }
  body #DowloadList .ant-form-item-label,
  body #downLoadDrawer .ant-form-item-label {
    width: auto;
    padding-right: 8px;
    line-height: 32px; }
  body #DowloadList .input-prefix,
  body #downLoadDrawer .input-prefix {
    margin-left: 16px;
    background: #00a1e6;
    font-size: 16px;
    color: white;
    font-weight: bold;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 2px 2px 2px 2px; }
  body #DowloadList .ant-drawer-title,
  body #downLoadDrawer .ant-drawer-title {
    color: #00a1e6; }
  body #DowloadList .drawer-but-container,
  body #downLoadDrawer .drawer-but-container {
    display: 'flex';
    justify-content: 'flex-end';
    border-top: 1px solid #e9e9e9;
    padding: 10px; }
  body #DowloadList .drawer-order-detail-item,
  body #downLoadDrawer .drawer-order-detail-item {
    border-bottom: 1px dashed #e9e9e9;
    margin-bottom: 10px; }
  body #DowloadList .ant-modal-title,
  body #downLoadDrawer .ant-modal-title {
    display: flex;
    justify-content: center;
    color: #00a1e6;
    font-size: 16px; }
  body #DowloadList .ant-modal-header,
  body #downLoadDrawer .ant-modal-header {
    border-bottom: 1px solid #00a1e6; }
  body #DowloadList .DataSearchForm,
  body #downLoadDrawer .DataSearchForm {
    border: 1px solid rgba(214, 214, 214, 0.64);
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px !important;
    padding-right: 24px !important; }
    body #DowloadList .DataSearchForm .ant-form-item-label,
    body #downLoadDrawer .DataSearchForm .ant-form-item-label {
      line-height: 40px;
      padding-left: 0 !important; }
    body #DowloadList .DataSearchForm .ant-btn:hover,
    body #DowloadList .DataSearchForm .ant-btn:focus,
    body #DowloadList .DataSearchForm .ant-btn:active,
    body #DowloadList .DataSearchForm .ant-btn.active,
    body #downLoadDrawer .DataSearchForm .ant-btn:hover,
    body #downLoadDrawer .DataSearchForm .ant-btn:focus,
    body #downLoadDrawer .DataSearchForm .ant-btn:active,
    body #downLoadDrawer .DataSearchForm .ant-btn.active {
      background-color: #00a1e6;
      color: white; }
  body #MyData .myDatas .myDataCard .ant-card-body {
    padding-left: 0px;
    padding-top: 0px; }
  body #MyData .myDatas .myDataAmountTitle {
    width: 80px;
    text-align: right; }
  body #MyData .myDatas .myDataAmount {
    text-align: right;
    padding-right: 50px !important; }
  body #MyData .myDatas .myDataOpt {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0; }
    body #MyData .myDatas .myDataOpt .myDataOptBtn {
      height: 54px;
      line-height: 54px;
      width: 80px;
      float: left;
      text-align: center; }
      body #MyData .myDatas .myDataOpt .myDataOptBtn a {
        padding: 16px 8px; }
    body #MyData .myDatas .myDataOpt .btnAera {
      padding: 5px 11.5px;
      border-left: 1px #d6d6d6 solid; }
    body #MyData .myDatas .myDataOpt .myDataOptBtn:hover a {
      color: #ffffff;
      background: #00a1e6; }
    body #MyData .myDatas .myDataOpt .myDataOptBtn:hover .btnAera {
      border-left-color: #00a1e6; }
  body #MyData .myDatas .table-header {
    padding-bottom: 16px;
    padding-left: 24px;
    padding-top: 16px;
    background-color: white;
    margin-top: 10px;
    border: 1px solid #eff2f5; }
  body #MyData .myDatas .ant-table-thead > tr > th {
    background-color: white; }
  body #MyData .myDatas .ant-table-thead > tr {
    border: 1px solid rgba(214, 214, 214, 0.64);
    border-top: none; }
  body #orderCheck .customPanelStyle {
    background: '#f7f7f7';
    border-radius: 4px;
    margin-bottom: 24px;
    border: 0;
    overflow: hidden; }
  body #orderCheck .order-check-container {
    padding: 0px 10px 0px 25px;
    margin-top: 10px;
    background-color: #fafafa; }
  body #orderCheck .order-check-package-container {
    background-color: #fafafa;
    display: 'flex';
    flex-direction: 'column';
    padding: 16px 10px 0px 0px; }
    body #orderCheck .order-check-package-container .dataItem {
      margin-top: 8px; }
  body #orderCheck .order-check-box-container {
    border-left: 1px dashed #d6d6d6;
    padding: 0px 0px 0px 23px; }
    body #orderCheck .order-check-box-container .tableColumnItem {
      font-size: 14px;
      margin-bottom: 8px; }
    body #orderCheck .order-check-box-container .ant-input,
    body #orderCheck .order-check-box-container .ant-input-number {
      border-radius: 16px !important;
      border-color: #00a1e6 !important; }
    body #orderCheck .order-check-box-container .ant-select-selection {
      border-color: #00a1e6 !important; }
    body #orderCheck .order-check-box-container .ant-input-number-handler-wrap {
      display: none; }
  body #orderCheck .order-check-collapse-container .ant-collapse-item-active .ant-collapse-header {
    font-weight: bold !important;
    padding-left: 24px !important; }
    body #orderCheck .order-check-collapse-container .ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow {
      right: 16px !important;
      left: 0px; }
      body #orderCheck .order-check-collapse-container .ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow svg {
        float: right;
        transform: rotate(180deg) !important; }
  body #orderCheck .order-check-collapse-container .ant-collapse-item .ant-collapse-header {
    font-weight: normal;
    padding-left: 24px !important; }
    body #orderCheck .order-check-collapse-container .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
      right: 16px !important;
      left: 0px; }
      body #orderCheck .order-check-collapse-container .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg {
        width: 1.5em;
        height: 1.51em;
        float: right; }
  body #orderCheck .order-check-right-container {
    display: 'flex';
    flex-direction: 'column';
    height: 100% !important;
    padding: 0px 10px 0px 24px; }
    body #orderCheck .order-check-right-container .rowItem {
      padding-top: 8px;
      padding-bottom: 8px; }
  body #orderCheck .order-security-box {
    border-right: 10px solid white;
    padding-right: 24px; }
  body #CommonOperation .operation-guide-top-title,
  body #FAQPage .operation-guide-top-title {
    font-size: 16px;
    color: #00a1e6;
    margin-top: 16px; }
  body #CommonOperation .operation-guide-second-title,
  body #FAQPage .operation-guide-second-title {
    font-size: 14px;
    color: #000000;
    margin-top: 16px;
    font-weight: bold; }
  body #CommonOperation .peration-guide-image,
  body #FAQPage .peration-guide-image {
    border: 2px solid #e6fcff;
    width: 536px; }
  body #CommonOperation .operation-guide-third-title,
  body #FAQPage .operation-guide-third-title {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-top: 6px; }
  body #CommonOperation .FAQ-right-container,
  body #FAQPage .FAQ-right-container {
    position: fixed;
    padding-left: 7px; }
  body #CommonOperation .FAQ-left-container,
  body #FAQPage .FAQ-left-container {
    border-right: 1px solid #d6d6d6;
    height: 100%; }
  body #CommonOperation .ant-spin-container,
  body #FAQPage .ant-spin-container {
    height: 100%; }
  body #CommonOperation .ant-spin-nested-loading,
  body #FAQPage .ant-spin-nested-loading {
    height: 100%; }
  body #CommonOperation .FAQ-answer-container .ant-collapse-item-active .ant-collapse-header,
  body #FAQPage .FAQ-answer-container .ant-collapse-item-active .ant-collapse-header {
    color: #00a1e6; }
  body #CommonOperation .FAQ-answer-container .ant-collapse-header,
  body #FAQPage .FAQ-answer-container .ant-collapse-header {
    font-weight: bolder;
    padding-left: 0px !important; }
  body #CommonOperation .FAQ-answer-container .lengthControl, body #CommonOperation .FAQ-answer-container #DataSearch .data_detail .data_detail_b .de_val, body #DataSearch .data_detail .data_detail_b #CommonOperation .FAQ-answer-container .de_val, body #CommonOperation .FAQ-answer-container #DataSearch .popov, body #DataSearch #CommonOperation .FAQ-answer-container .popov,
  body #FAQPage .FAQ-answer-container .lengthControl,
  body #FAQPage .FAQ-answer-container #DataSearch .data_detail .data_detail_b .de_val,
  body #DataSearch .data_detail .data_detail_b #FAQPage .FAQ-answer-container .de_val,
  body #FAQPage .FAQ-answer-container #DataSearch .popov,
  body #DataSearch #FAQPage .FAQ-answer-container .popov {
    width: 153px; }
  body #CommonOperation .main-color,
  body #FAQPage .main-color {
    color: #00a1e6; }
  body #CommonOperation .black-color,
  body #FAQPage .black-color {
    color: #000; }
  body #CommonOperation .imgFullWidth,
  body #FAQPage .imgFullWidth {
    width: 100%; }
  body #Introduction {
    margin-top: 33px; }
    body #Introduction .blockCenter {
      display: block;
      margin-left: auto;
      margin-right: auto; }
    body #Introduction .Introduction {
      padding-bottom: 1em; }
      body #Introduction .Introduction .divider {
        top: 85px;
        position: relative;
        z-index: 1;
        margin-left: 5%;
        min-width: 90%;
        width: 90%; }
      body #Introduction .Introduction .ant-carousel .slick-slide {
        height: 100%;
        background: #fff;
        overflow: hidden; }
        body #Introduction .Introduction .ant-carousel .slick-slide .description {
          margin-top: 11em;
          padding: 1em;
          padding-top: 6em;
          height: 25em;
          margin-bottom: 1em; }
      body #Introduction .Introduction .ant-carousel .slick-slide h3 {
        color: #000; }
      body #Introduction .Introduction .ant-carousel .slick-dots:before {
        content: '';
        position: absolute;
        left: 5%;
        top: -2px;
        bottom: 0;
        right: auto;
        height: 2px;
        width: 90%;
        background-color: #d6d6d6; }
      body #Introduction .Introduction .ant-carousel .slick-dots {
        top: 33%;
        margin-bottom: -8%; }
        body #Introduction .Introduction .ant-carousel .slick-dots li {
          z-index: 8; }
          body #Introduction .Introduction .ant-carousel .slick-dots li button {
            width: 64px;
            height: 54px;
            opacity: 1;
            margin-top: -27px;
            margin-bottom: 12px;
            margin-left: 0;
            margin-right: 0; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(1) {
          width: 22%; }
          body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(1) button {
            background-image: url("/resource/intro1.svg");
            background-size: 100% 100%;
            background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(1).slick-active button {
          background-image: url("/resource/intro1(1).svg");
          background-size: 100% 100%;
          background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(1):after {
          content: '数据管理';
          position: absolute;
          left: 0%; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(1).slick-active:after {
          content: '数据管理';
          color: #00a1e6;
          font-weight: bold; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(2) {
          width: 22%; }
          body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(2) button {
            background-image: url("/resource/intro2.svg");
            background-size: 100% 100%;
            background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(2).slick-active button {
          background-image: url("/resource/intro2(1).svg");
          background-size: 100% 100%;
          background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(2):after {
          content: '数据检索';
          position: absolute;
          left: 0%; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(2).slick-active:after {
          content: '数据检索';
          color: #00a1e6;
          font-weight: bold; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(3) {
          width: 22%; }
          body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(3) button {
            background-image: url("/resource/intro3.svg");
            background-size: 100% 100%;
            background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(3).slick-active button {
          background-image: url("/resource/intro3(1).svg");
          background-size: 100% 100%;
          background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(3):after {
          content: '数据使用';
          position: absolute;
          left: 0%; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(3).slick-active:after {
          content: '数据使用';
          color: #00a1e6;
          font-weight: bold; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(4) {
          width: 22%; }
          body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(4) button {
            background-image: url("/resource/intro4.svg");
            background-size: 100% 100%;
            background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(4).slick-active button {
          background-image: url("/resource/intro4(1).svg");
          background-size: 100% 100%;
          background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(4):after {
          content: '安全盒';
          position: absolute;
          left: 3.5%; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(4).slick-active:after {
          content: '安全盒';
          color: #00a1e6;
          font-weight: bold; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(5) button {
          background-image: url("/resource/intro5.svg");
          background-size: 100% 100%;
          background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(5).slick-active button {
          background-image: url("/resource/intro5(1).svg");
          background-size: 100% 100%;
          background-color: transparent; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(5):after {
          content: '下载中心'; }
        body #Introduction .Introduction .ant-carousel .slick-dots li:nth-child(5).slick-active:after {
          content: '下载中心';
          color: #00a1e6;
          font-weight: bold; }
        body #Introduction .Introduction .ant-carousel .slick-dots .slick-active button {
          width: 64px;
          height: 54px; }
    body #Introduction main {
      display: block; }
  body #Login .login-form-button {
    width: 240px;
    height: 36px;
    font-size: 14px;
    border-radius: 18px; }
  body #Login .toFlex {
    display: flex; }
  body #Login .inputBefore {
    background-color: #d6d6d6;
    font-size: 30px; }
  body #Login .inputSet {
    height: 32px;
    width: 370px; }
  body #Login .login-container {
    display: 'flex';
    align-items: center;
    justify-content: center;
    height: 93vh; }
    body #Login .login-container .login-logo > div {
      display: flex;
      justify-content: center; }
      body #Login .login-container .login-logo > div > div {
        height: 90px;
        width: 184px; }
        body #Login .login-container .login-logo > div > div i {
          font-size: 90px; }
    body #Login .login-container .login-logo svg {
      width: 2em;
      height: 1em; }
    body #Login .login-container .rememberCheckbox {
      color: #999999;
      justify-self: flex-start; }
    body #Login .login-container .ant-input-group-addon {
      padding: 0px 0px;
      padding-left: 1px; }
  body #Login .login-bottom-container {
    height: 7vh;
    min-height: 60px; }
    body #Login .login-bottom-container .login-bottom-center-element {
      border-left: 1px solid #d6d6d6;
      border-right: 1px solid #d6d6d6;
      margin-left: 64px !important;
      margin-right: 64px;
      padding-left: 64px;
      padding-right: 64px; }
  body #Login .center-set {
    display: flex;
    justify-content: center; }
  body #Login .up-foot {
    width: 432px;
    margin-top: 18px;
    display: flex;
    flex-direction: row; }
    body #Login .up-foot .left-word {
      width: 180px; }
    body #Login .up-foot .right-word {
      width: 250px;
      text-align: right; }
  body #CheckVmListItemView .CheckVmListItemView {
    border: 1px solid #d6d6d6;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    border-radius: 24px; }
    body #CheckVmListItemView .CheckVmListItemView .input-prefix {
      margin-left: 16px;
      color: 'red';
      font-size: 1em; }
  body #CheckVmListItemView .CheckVmListItemView:hover {
    border: 1px solid #00a1e6; }
  body #CheckVmListItemView .CheckVmListItemView-selected {
    border: 1px solid #00a1e6;
    border-radius: 24px;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
    body #CheckVmListItemView .CheckVmListItemView-selected .input-prefix {
      margin-left: 16px;
      color: #fafafa;
      font-size: 1em; }
    body #CheckVmListItemView .CheckVmListItemView-selected .input-prefix-selected {
      margin-left: 16px;
      color: #00a1e6;
      font-size: 1em; }
  body #TransactionLogs .tableListForm {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-self: 'center'; }
  body #TransactionLogs .drawer-but-container {
    display: 'flex';
    justify-content: 'flex-end';
    border-top: 1px solid #e9e9e9;
    padding: 10px; }
  body #TransactionLogs .drawer-order-detail-item {
    border-bottom: 1px dashed #e9e9e9;
    margin-bottom: 10px; }
  body #TransactionLogs .trade-columns-scores {
    text-align: right !important; }
  body #TransactionLogs .ant-tabs-tab-active {
    color: #00a1e6 !important; }
  body #TransactionLogs .ant-tabs-bar {
    margin: 0; }
  body #TransactionLogs .recharge {
    margin-bottom: 10px; }
    body #TransactionLogs .recharge span:nth-child(1) {
      color: rgba(0, 0, 0, 0.65);
      font-weight: bold; }
    body #TransactionLogs .recharge span:nth-child(2) {
      color: rgba(0, 0, 0, 0.65);
      margin-left: 10px; }
  body #VMOrders .page-container {
    margin-bottom: 71px;
    background-color: white; }
    body #VMOrders .page-container .table-pagination {
      text-align: center;
      margin-top: 48px; }
  body #VMOrders .isshowpwd {
    margin-left: 8px;
    position: relative;
    top: 3px; }
    body #VMOrders .isshowpwd * {
      font-size: 18px; }
  body #VMOrders .custom-card-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    flex: 1; }
    body #VMOrders .custom-card-button .ant-btn[disabled] {
      color: #d6d6d6;
      border: none;
      background-color: white; }
  body #VMOrders .custom-card-button-text {
    color: #00a1e6;
    border: none; }
    body #VMOrders .custom-card-button-text:hover, body #VMOrders .custom-card-button-text:active, body #VMOrders .custom-card-button-text:focus {
      color: white !important; }
  body #VMOrders .no-data-container {
    display: 'flex';
    justify-content: 'center';
    height: '169px'; }
  body #UserGrades .grade-table-header {
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;
    padding-left: 24px;
    padding-top: 16px;
    background-color: #fafafa; }
  body #UserGrades .select-form {
    width: 156px;
    padding-left: 10px; }
  body #UserGrades .normal-search-button {
    margin-left: 64px;
    width: 96px; }
  body #UserGrades .header-line {
    padding-left: 24px;
    padding-bottom: 17px;
    display: flex;
    align-items: center; }
    body #UserGrades .header-line .all-score {
      color: #00a1e6;
      margin-right: 60px;
      font-weight: bold;
      font-size: 24px;
      margin-left: 8px; }
    body #UserGrades .header-line .score-plus {
      color: #52c41a;
      margin-right: 60px;
      margin-left: 8px; }
    body #UserGrades .header-line .score-minus {
      color: #d0021b;
      margin-right: 60px;
      margin-left: 8px; }
  body #UserGrades .grade-drawer .ant-drawer-body {
    padding: 24px 0px 24px 0px; }
  body #UserGrades .grade-drawer .grade-drawer-content {
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #e8e8e8; }
  body #UserGrades .ant-table-body {
    margin: 0; }
  body #UserGrades .ant-table-thead th {
    padding: 8px 16px; }
  body #UserGrades .ant-table-tbody td {
    padding: 14px 16px; }
  body #UserGrades .table-header {
    padding-bottom: 16px;
    padding-left: 24px;
    padding-top: 16px;
    background-color: white;
    margin-top: 10px;
    border: 1px solid #eff2f5; }
  body #UserGrades .ant-table-thead > tr > th {
    background-color: white; }
  body #UserGrades .ant-table-thead > tr {
    border-top: none; }
  body #UserGrades .ant-table-small {
    border-top: none;
    border-left: 1px solid rgba(214, 214, 214, 0.64);
    border-right: 1px solid rgba(214, 214, 214, 0.64);
    border-bottom: 1px solid rgba(214, 214, 214, 0.64);
    border-radius: 0px; }
  body #UserGrades .status-plus {
    color: #52c41a; }
  body #UserGrades .status-minus {
    color: #d0021b; }
  body #DataManager {
    /* 上箭头 */ }
    body #DataManager .data-manager-container {
      padding-top: 51px; }
    body #DataManager .ant-table-thead > tr {
      border-bottom: 1px solid rgba(214, 214, 214, 0.32); }
    body #DataManager .ant-table-thead > tr > th {
      background-color: white;
      color: rgba(0, 0, 0, 0.85); }
    body #DataManager .ant-table-thead > tr:first-child > th:first-child {
      border-left: 1px solid rgba(214, 214, 214, 0.32); }
    body #DataManager .ant-table-thead > tr:first-child > th:last-child {
      border-right: 1px solid rgba(214, 214, 214, 0.32); }
    body #DataManager .data-manager-container {
      padding-top: 51px; }
      body #DataManager .data-manager-container .ant-steps-item-process .ant-steps-item-icon {
        background: #00a1e6;
        border-color: #00a1e6; }
      body #DataManager .data-manager-container .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
        color: #00a1e6;
        font-weight: normal; }
      body #DataManager .data-manager-container .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: #00a1e6; }
      body #DataManager .data-manager-container .ant-steps-item-finish .ant-steps-item-icon {
        border-color: #00a1e6; }
      body #DataManager .data-manager-container .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
        border-color: rgba(0, 0, 0, 0.64); }
      body #DataManager .data-manager-container .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.64);
        border-color: rgba(0, 0, 0, 0.64); }
      body #DataManager .data-manager-container .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.64); }
      body #DataManager .data-manager-container .ant-table-thead > tr {
        border-bottom: 1px solid rgba(214, 214, 214, 0.32); }
      body #DataManager .data-manager-container .ant-table-thead > tr > th {
        background-color: white;
        color: rgba(0, 0, 0, 0.85); }
      body #DataManager .data-manager-container .ant-table-thead > tr:first-child > th:first-child {
        border-left: 1px solid rgba(214, 214, 214, 0.32); }
      body #DataManager .data-manager-container .ant-table-thead > tr:first-child > th:last-child {
        border-right: 1px solid rgba(214, 214, 214, 0.32); }
      body #DataManager .data-manager-container .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: #00a1e6;
        border-color: #00a1e6; }
      body #DataManager .data-manager-container .ant-radio-button-wrapper:hover {
        color: #00a1e6; }
      body #DataManager .data-manager-container .list-type-header .ant-radio-button-wrapper-checked:hover {
        color: #fff !important; }
      body #DataManager .data-manager-container .ant-form-item {
        margin-bottom: 0px; }
      body #DataManager .data-manager-container .ant-input:focus {
        box-shadow: none; }
      body #DataManager .data-manager-container .ant-radio-inner::after {
        left: 3px; }
    body #DataManager .hover_show1 {
      display: flex;
      align-items: center; }
    body #DataManager .hover_cor {
      transition: all 1s; }
      body #DataManager .hover_cor .hover_show_all {
        display: none; }
      body #DataManager .hover_cor .hover_show {
        display: flex;
        align-items: center; }
      body #DataManager .hover_cor:hover .hover_show {
        display: none; }
      body #DataManager .hover_cor:hover .hover_show_all {
        display: flex;
        border: 1px solid #00a1e6;
        border-radius: 16px; }
        body #DataManager .hover_cor:hover .hover_show_all .exp_box {
          display: flex;
          align-items: center;
          padding: 5px 13px; }
        body #DataManager .hover_cor:hover .hover_show_all .tit_b {
          display: flex;
          align-items: center;
          margin-left: 10px; }
          body #DataManager .hover_cor:hover .hover_show_all .tit_b .tit_r {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            border: 1px solid #00a1e6;
            margin-right: 5px; }
          body #DataManager .hover_cor:hover .hover_show_all .tit_b .tit_n {
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #00a1e6; }
    body #DataManager .tools-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 24px; }
    body #DataManager .radio-container {
      border: 1px solid rgba(214, 214, 214, 0.64);
      border-radius: 23px; }
    body #DataManager .export {
      margin-left: 8px;
      color: #00a1e6;
      cursor: pointer; }
    body #DataManager .disable {
      color: #00a1e6 !important; }
    body #DataManager .list-type-header {
      margin-top: 48px;
      display: flex;
      justify-content: flex-end; }
      body #DataManager .list-type-header .ant-btn {
        border: none;
        font-weight: bold; }
      body #DataManager .list-type-header .ant-radio-button-wrapper {
        border: none;
        border-radius: 16px;
        outline: none !important; }
      body #DataManager .list-type-header .ant-radio-button-wrapper:first-child {
        border-radius: 16px; }
      body #DataManager .list-type-header .ant-radio-button-wrapper:last-child {
        border-radius: 16px; }
      body #DataManager .list-type-header .ant-radio-button-wrapper-checked::before {
        background-color: white !important; }
      body #DataManager .list-type-header .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: #ffffff; }
    body #DataManager .list-type-center-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 23px;
      border: 1px solid rgba(214, 214, 214, 0.64); }
    body #DataManager .list-type-center-btn {
      flex: 1;
      height: 32px;
      color: #00a1e6; }
    body #DataManager .list-type-left-container {
      flex: 1;
      display: flex;
      align-items: center; }
    body #DataManager .list-type-right-container {
      flex: 1px;
      display: flex;
      align-items: center; }
    body #DataManager .share-steps-container {
      padding-top: 0px;
      width: 700px; }
    body #DataManager .project-input {
      border: none;
      padding-left: 16px;
      padding-right: 24px;
      border-bottom: 1px solid rgba(214, 214, 214, 0.64); }
    body #DataManager .genetic-from-select {
      width: 156px; }
    body #DataManager .genetic-from-select-container {
      display: 'flex';
      margin-top: '26px';
      align-items: center; }
    body #DataManager .jiantou-before {
      display: block;
      content: '';
      border-width: 8px 8px 8px 8px;
      border-style: solid;
      border-color: transparent transparent #ff4d4d transparent;
      width: 10px;
      /* 定位 */ }
    body #DataManager .show-top-border {
      border-top: 1px solid rgba(214, 214, 214, 0.64); }
    body #DataManager .sequences-uuid-table {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px; }
    body #DataManager .top {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 150px;
      top: -15px;
      z-index: 2; }
    body #DataManager .top-image {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 250px;
      top: -15px;
      z-index: 2; }
    body #DataManager .top-arrow1,
    body #DataManager .top-arrow2 {
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      /*兼容ie8-*/
      border-top: 6px transparent dashed;
      border-left: 10px transparent dashed;
      border-right: 10px transparent dashed;
      border-bottom: 10px white solid;
      overflow: hidden; }
    body #DataManager .top-arrow1 {
      border-bottom: 10px rgba(214, 214, 214, 0.64) solid; }
    body #DataManager .top-arrow2 {
      top: 1px;
      /*重要*/
      border-bottom: 10px white solid; }
    body #DataManager .genetic-form-view {
      margin-top: 10px;
      display: 'flex';
      align-items: 'flex-end';
      margin-bottom: '24px';
      position: 'relative'; }
    body #DataManager .original-form-header .ant-input-affix-wrapper .ant-input {
      border-radius: 16px; }
    body #DataManager .dm-table-action {
      color: #00a1e6;
      cursor: pointer; }
    body #DataManager .dm-table-action-disable {
      color: rgba(0, 0, 0, 0.32);
      cursor: pointer; }
    body #DataManager .type-header-icon {
      background-color: white;
      color: #00a1e6;
      border-radius: 16px;
      border-color: #00a1e6;
      border-width: 1px; }
    body #DataManager .type-header-icon:hover {
      background-color: #00a1e6;
      color: white;
      border-radius: 16px;
      border-color: #00a1e6;
      border-width: 1px; }
    body #DataManager .text-last {
      width: 75px;
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute-all-lines; }
  body #MemberManage .member-manage .task-header {
    display: flex;
    justify-content: flex-end; }
    body #MemberManage .member-manage .task-header .ant-btn {
      border: none;
      font-weight: bold; }
    body #MemberManage .member-manage .task-header .ant-radio-button-wrapper {
      border: none;
      border-radius: 16px;
      padding-left: 30px;
      padding-right: 30px;
      outline: none !important; }
    body #MemberManage .member-manage .task-header .ant-radio-button-wrapper:first-child {
      border-radius: 16px; }
    body #MemberManage .member-manage .task-header .ant-radio-button-wrapper:last-child {
      border-radius: 16px; }
    body #MemberManage .member-manage .task-header .ant-radio-button-wrapper-checked::before {
      background-color: white !important; }
    body #MemberManage .member-manage .task-header .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: #ffffff; }
  body #MemberManage .member-manage .ant-divider-inner-text {
    padding: 0px; }
  body #MemberManage .member-manage .ant-form-item {
    margin-bottom: 0px; }
  body #MemberManage .member-manage .ant-input-affix-wrapper .ant-input {
    border-radius: 16px; }
  body #MemberManage .member-manage .ant-table-thead > tr {
    border-bottom: 1px solid rgba(214, 214, 214, 0.32); }
  body #MemberManage .member-manage .ant-table-thead > tr > th {
    background-color: white;
    color: rgba(0, 0, 0, 0.85); }
  body #MemberManage .member-manage .ant-table-thead > tr:first-child > th:first-child {
    border-left: 1px solid rgba(214, 214, 214, 0.32); }
  body #MemberManage .member-manage .ant-table-thead > tr:first-child > th:last-child {
    border-right: 1px solid rgba(214, 214, 214, 0.32); }
  body #MemberManage .member-manage .ant-divider-horizontal.ant-divider-with-text {
    margin-top: 0px;
    margin-bottom: 10px; }
  body #MemberManage .list-type-center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    border: 1px solid rgba(214, 214, 214, 0.64); }
  body #MemberManage .role-permissions {
    color: rgba(0, 0, 0, 0.64);
    padding-right: 10px;
    padding-left: 10px; }
  body #MemberManage .left-border {
    border-left: 1px solid #d6d6d6; }
  body #MemberManage .role-permissions-more {
    padding-left: 10px;
    color: #00a1e6;
    cursor: pointer; }
  body #MemberManage .status-green {
    color: #52c41a; }
  body #MemberManage .status-red {
    color: #a62024; }
  body #MemberManage .memberAction {
    display: flex;
    align-items: center; }
  body #MemberManage .toFlex {
    display: flex; }
  body #MemberManage .btn {
    color: #00a1e6;
    cursor: pointer; }
  body #MemberManage .toFlexCenter {
    display: flex;
    align-items: center; }
  body #MemberManage .normal-search-button {
    border: 1px solid #00a1e6;
    color: #00a1e6;
    height: 32px;
    border-radius: 16px;
    padding-left: 31px;
    padding-right: 31px; }
  body #MemberManage .btn-set {
    margin-left: 48px;
    margin-right: 64px; }
    body #MemberManage .btn-set:hover, body #MemberManage .btn-set:active, body #MemberManage .btn-set:focus {
      color: #fff; }
  body #MemberManage .whitebackground {
    background-color: #ffffff; }
  body #MemberManage .mt30 {
    margin-top: 30px; }
  body #VMOrders .page-container {
    margin-bottom: 71px;
    background-color: white; }
    body #VMOrders .page-container .table-pagination {
      text-align: center;
      margin-top: 48px; }
  body #VMOrders .isshowpwd {
    margin-left: 8px;
    position: relative;
    top: 3px; }
    body #VMOrders .isshowpwd * {
      font-size: 18px; }
  body #VMOrders .custom-card-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    flex: 1; }
    body #VMOrders .custom-card-button .ant-btn[disabled] {
      color: #d6d6d6;
      border: none;
      background-color: white; }
  body #VMOrders .custom-card-button-text {
    color: #00a1e6;
    border: none; }
    body #VMOrders .custom-card-button-text:hover, body #VMOrders .custom-card-button-text:active, body #VMOrders .custom-card-button-text:focus {
      color: white !important; }
  body #VMOrders .no-data-container {
    display: 'flex';
    justify-content: 'center';
    height: '169px'; }
  body #ProjectMembers .standard-p {
    margin-left: 24px;
    margin-right: 24px; }
  body #ProjectMembers .main {
    margin-top: 25px; }
  body #ProjectMembers .Breadcrumb {
    float: right; }
  body #ProjectMembers .ant-breadcrumb a:hover {
    color: #00a1e6; }
  body #ProjectMembers .title {
    margin-top: 28px; }
  body #ProjectMembers .title-content::before {
    content: '●  ';
    color: #00a1e6; }
  body #ProjectMembers .new-mem {
    float: right; }
  body #ProjectMembers .ipt {
    background: none;
    outline: none !important;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  body #ProjectMembers .editName {
    font-weight: 100; }
  body #ProjectMembers .new-mem {
    height: 32px;
    width: 96px;
    margin-top: -8px; }
  body #ProjectMembers .close {
    color: #d0021b; }
  body #ProjectMembers .open {
    color: #52c41a; }
  body #ProjectMembers .new-mem-bt {
    height: 32px;
    width: 96px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: #fff;
    color: #00a1e6; }
  body #ProjectMembers .renderFormHeader {
    height: 5rem;
    display: flex;
    align-items: center; }
    body #ProjectMembers .renderFormHeader .table-header {
      width: 100%; }
  body #ProjectMembers .return-btn {
    color: #000; }
  body #ProjectMembers .Breadcrumb span {
    color: rgba(0, 0, 0, 0.45); }
  body #ProjectTasks .standard-p {
    margin-left: 24px;
    margin-right: 24px; }
  body #ProjectTasks .main {
    margin-top: 25px; }
  body #ProjectTasks .Breadcrumb {
    float: right; }
  body #ProjectTasks .ant-breadcrumb a:hover {
    color: #00a1e6; }
  body #ProjectTasks .title {
    margin-top: 28px; }
  body #ProjectTasks .title-content::before {
    content: '●  ';
    color: #00a1e6; }
  body #ProjectTasks .new-task {
    float: right;
    height: 32px;
    width: 96px;
    margin-top: -8px; }
  body #ProjectTasks .underline {
    background: none;
    outline: none !important;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #d6d6d6; }
    body #ProjectTasks .underline:focus {
      box-shadow: none; }
  body #ProjectTasks .editName {
    font-weight: 100; }
  body #ProjectTasks .newLine {
    margin-top: 1rem; }
  body #ProjectTasks .hide {
    display: none; }
  body #ProjectTasks .ant-calendar-picker .ant-input {
    border-radius: 16px; }
  body #ProjectTasks .ant-form-item-control #username {
    border-radius: 16px; }
  body #ProjectTasks .new-fin {
    height: 32px;
    width: 96px;
    margin-top: -16px;
    float: right; }
  body #ProjectTasks .seqArea {
    margin-top: 1rem; }
  body #ProjectTasks .taskname {
    width: 100px; }
  body #ProjectTasks .description {
    width: 250px; }
  body #ProjectTasks .return-btn {
    color: black; }
  body #ProjectTasks .Breadcrumb span {
    color: rgba(0, 0, 0, 0.45); }
  body #root {
    height: 100%; }
  body .center {
    display: flex;
    align-items: center;
    justify-content: center; }
  body .lengthControl, body #DataSearch .data_detail .data_detail_b .de_val, body #DataSearch .popov {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  body .hide {
    display: none; }
  body .standard {
    border-bottom: 1px solid #e8e8e8;
    padding-left: 24px !important;
    padding-right: 24px !important; }
  body .clear {
    clear: both; }

.ant-pagination-item-link,
.ant-pagination-item,
.ant-btn,
.ant-select-selection {
  border-radius: 16px !important; }

.ant-btn-primary {
  background-color: #00a1e6;
  border-color: #00a1e6; }

.ant-pagination-item-active {
  background-color: #00a1e6 !important;
  border: 0; }
  .ant-pagination-item-active:hover {
    border: 0; }
  .ant-pagination-item-active a {
    color: #fff !important; }

.ant-table-body table thead th:nth-of-type(1) {
  padding-left: 24px; }

.ant-table-body table thead th:last-of-type {
  padding-right: 24px; }

.ant-table-body table tbody td:nth-of-type(1) {
  padding-left: 24px; }

.ant-table-body table tbody td:last-of-type {
  padding-right: 24px; }

.ant-table-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  float: none !important;
  margin-top: 64px !important; }

.main-layout header .Menu {
  background: transparent;
  color: rgba(0, 0, 0, 0.64) !important; }

.ant-layout {
  background: #fff !important; }

.ant-table-thead {
  background-color: #fbfbfd;
  height: 49px; }

.ant-radio-inner::after {
  left: 25%; }

.menu-right-item:hover {
  background-color: #fff;
  color: #00a1e6; }

.table-num {
  text-align: right; }

.table-opt-area {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 54px;
  line-height: 54px; }
  .table-opt-area .btngroup {
    padding-top: 2px;
    padding-left: 8px; }
  .table-opt-area a {
    padding: 16px 8px; }
  .table-opt-area a:hover {
    background-color: #00a1e6;
    color: #ffffff; }
  .table-opt-area a:active {
    background-color: rgba(0, 161, 230, 0.8); }
  .table-opt-area .unClick {
    color: #d6d6d6; }
  .table-opt-area .unClick:hover {
    color: #ffffff;
    background-color: #d6d6d6; }
  .table-opt-area .optline {
    height: 32px; }

.table-num {
  text-align: right; }

.page-container .clear {
  clear: both; }

.page-container .ant-table-column-has-actions {
  background-clip: padding-box !important; }

.page-container .ant-table-thead > tr > th {
  padding: 0 16px; }

.page-container .ant-table-tbody > tr > td {
  padding: 0 16px; }

.page-container .ant-table-thead > tr > th {
  background-color: #ffffff; }

.page-container .ant-table-thead > tr {
  border: 1px solid rgba(214, 214, 214, 0.64); }

.page-container .ant-card-body {
  padding: 0; }

.page-container .prjectinfo {
  padding: 12px 24px;
  border-top: 1px rgba(214, 214, 214, 0.32) solid;
  border-bottom: 1px rgba(214, 214, 214, 0.32) solid; }
  .page-container .prjectinfo .prjectinfo-line {
    border-left: 1px #d6d6d6 solid;
    margin: 0 16px;
    padding: 3px 0; }
  .page-container .prjectinfo .prjectinfo-btn {
    color: #00a1e6;
    padding: 8px 8px; }
  .page-container .prjectinfo .prjectinfo-btn:hover {
    color: #ffffff;
    background-color: #00a1e6; }
  .page-container .prjectinfo label {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.64); }

.page-container .prjectdesc {
  padding: 16px 24px; }

.page-container .routeHeaderContainer .ant-row-flex-start {
  height: 32px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.45); }

.page-container .routeHeaderContainer .ant-row {
  height: 64px;
  line-height: 60px;
  margin-top: 0px !important; }
  .page-container .routeHeaderContainer .ant-row * {
    color: rgba(0, 0, 0, 0.85) !important; }

.page-container .queryform .queryform-row {
  width: 100%;
  display: flex;
  padding-left: 24px;
  background-color: white;
  padding: 16px 24px;
  border: 1px solid rgba(214, 214, 214, 0.68); }

.page-container .queryform .queryform-col {
  display: flex;
  align-items: center;
  margin-right: 54px; }
  .page-container .queryform .queryform-col .ant-form-item-label {
    margin-right: 8px; }
  .page-container .queryform .queryform-col .queryform-select {
    width: 156px;
    margin-right: 90px; }
  .page-container .queryform .queryform-col .queryform-btn {
    width: 96px; }

.top-item-line {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  position: relative;
  z-index: 1;
  top: -3px;
  margin: 0 auto;
  transition: width 0.5s;
  background-color: #00a1e6; }

.top-item:not(.ant-menu-item-selected) .top-item-line {
  width: 0px; }

.top-item:not(.ant-menu-item-selected):hover .top-item-line {
  width: 100%; }

.ant-menu-item-selected .top-item-line {
  background-color: #00a1e6; }

.ant-menu-dark .top-item-line {
  background-color: #ffffff !important; }

.ant-menu-dark .ant-menu-item-selected {
  background: none !important; }

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none; }

.logo1-col {
  width: auto;
  margin-right: 32px;
  position: relative; }

.more-link-list .more-link {
  color: #000000;
  font-size: 13px; }

.more-link-list .more-link-line {
  border-left: 1px #d6d6d6 solid;
  margin: 0 16px;
  font-weight: bold; }

.more-link-list .notice:hover {
  color: #00a1e6; }

.more-link-list-index .more-link {
  color: #ffffff !important; }

.normal-search-button {
  border: 1px solid #00a1e6;
  color: #00a1e6;
  height: 32px;
  border-radius: 16px;
  padding-left: 31px;
  padding-right: 31px;
  display: flex;
  justify-content: center; }

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  background-color: #00a1e6;
  color: white; }

.no-box-shadow {
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.015) !important; }

.back-btn {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #00a1e6;
  border-color: #00a1e6; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00a1e6;
  border-color: #00a1e6; }

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #00a1e6; }

.roleListCheckboxGroup .ant-checkbox-group-item {
  display: block !important;
  margin-top: 0.5rem; }
