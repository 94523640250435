.CheckVmListItemView {
  border: 1px solid #d6d6d6;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  border-radius: 24px;
  .input-prefix {
    margin-left: 16px;
    color: 'red';
    font-size: 1em;
  }
}
.CheckVmListItemView:hover {
  border: 1px solid $main-color;
}
.CheckVmListItemView-selected {
  border: 1px solid $main-color;
  border-radius: 24px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .input-prefix {
    margin-left: 16px;
    color: #fafafa;
    font-size: 1em;
  }
  .input-prefix-selected {
    margin-left: 16px;
    color: #00a1e6;
    font-size: 1em;
  }
}
