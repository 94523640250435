.security-box-container {
  .box-header {
    display: flex;
    align-items: center;
    height: 64px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 24px;
    font-weight: bold;
  }
}
