.button-group {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.dividing-boreder {
  border-bottom: 1px dashed #e8e8e8;
  margin-bottom: 10px;
}
