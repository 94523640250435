@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.sci-orignal-component .ant-table-thead > tr > th {
  background: white;
  border-top: 1px solid #e8e8e8; }

.sci-orignal-component .ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid rgba(214, 214, 214, 0.64); }

.sci-orignal-component .ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid rgba(214, 214, 214, 0.64); }

.sci-orignal-component .ant-table-tbody > tr.ant-table-row-selected td {
  background: white; }

.sci-orignal-component .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6;
  color: #00a1e6;
  border-color: #00a1e6; }
  .sci-orignal-component .theme-normal-btn:hover, .sci-orignal-component .theme-normal-btn:active, .sci-orignal-component .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.sci-orignal-component .project-data-picker-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  width: 422px;
  height: 32px;
  border-bottom: none; }
  .sci-orignal-component .project-data-picker-input .ant-input {
    border-radius: 16px; }

.sci-orignal-component .dowload-container {
  display: flex;
  justify-items: center;
  color: #00a1e6;
  cursor: pointer;
  justify-content: flex-end; }

.sci-orignal-component .seq-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px; }

.load-data-modal .seq-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px; }

.dowload-data-modal .ant-modal-header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px; }
  .dowload-data-modal .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.dowload-data-modal .ant-modal-content {
  border-radius: 16px; }

.dowload-data-modal .ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; }
  .dowload-data-modal .ant-modal-body .ant-table {
    min-height: 300px; }

.dowload-data-modal .ant-divider-inner-text {
  padding: 0; }

.dowload-data-modal .ant-select {
  width: 240px; }

.dowload-data-modal .ant-modal-body {
  padding-bottom: 0px; }

.dowload-data-modal .ant-modal-footer {
  padding: 24px; }

.dowload-data-modal .ant-modal-close {
  right: 10px; }
  .dowload-data-modal .ant-modal-close .anticon {
    padding: 4px;
    border: 1px solid #d6d6d6;
    border-radius: 20px; }

.dowload-data-modal .theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.dowload-data-modal .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6; }
  .dowload-data-modal .theme-normal-btn:hover, .dowload-data-modal .theme-normal-btn:active, .dowload-data-modal .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.dowload-data-modal .modal-content-view {
  padding: 64px 104px; }
