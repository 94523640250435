.data-share-item-container {
  height: 128px;
  margin-bottom: 24px;
  .share-header {
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    background-color: #fafafa;
  }
  .share-container {
    height: 80px;
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(214, 214, 214, 0.64);
    border-right: 1px solid rgba(214, 214, 214, 0.64);
    border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  }
}
.ant-checkbox-inner {
  // background-color: $main-color !important;
  border-color: #00a1e6;
}

.ant-checkbox-checked {
  background-color: #00a1e6 !important;
  border-color: #00a1e6 !important;
}
