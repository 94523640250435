.DrawerBtnGroup {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}
.short {
  height: 1.2rem;
  margin-bottom: 1rem;
}
.img-group {
  img {
    width: 100%;
    margin-bottom: 32px;
    border-radius: 12px;
  }
}
