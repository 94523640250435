$line-color: rgba(214, 214, 214, 1);
$theme-color: #00a1e6;
$normal-border-line: 1px solid rgba(214, 214, 214, 0.64);
@mixin theme-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: $normal-border-line;
}

@mixin display-justify-content-center {
  display: flex;
  justify-content: center;
}
@mixin display-align-content-center {
  display: flex;
  align-items: center;
}

/*
    常用primary 按钮样式
*/
@mixin theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: $theme-color;
}
/*
      常用普通按钮样式
 */
@mixin theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: $theme-color;
  background: white;
  border: 1px solid $theme-color;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: $theme-color;
  }
}
/*  文本截断 css
*/
@mixin nowrap-elips {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*
 模态框基本样式
 */
@mixin normal-modal {
  //比较常用的模态框样式
  .ant-modal-header {
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    .ant-modal-title {
      display: flex;
      justify-content: center;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    .ant-table {
      min-height: 300px;
    }
  }

  .ant-divider-inner-text {
    padding: 0;
  }
}
/* 常用列表基本样式*/
@mixin normal-table {
  .ant-table-thead > tr > th {
    background: white;
    border-top: 1px solid #e8e8e8;
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-left: $normal-border-line;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-right: $normal-border-line;
  }
  .ant-table-tbody > tr.ant-table-row-selected td {
    background: white;
  }
}
/* 常用radio */
@mixin radio-more-type {
  display: flex;
  .ant-btn {
    border: none;
    font-weight: bold;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $theme-color;
    border-color: $theme-color;
  }
  .ant-radio-button-wrapper {
    border: none;
    border-radius: 16px;
    outline: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: white !important;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ffffff;
  }
}
