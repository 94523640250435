@keyframes rise {
  from {
    padding: 186px 0 186px;
  }

  to {
    padding: 121px 0 251px;
  }
}

@keyframes toHide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes toShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes toWhite {
  from {
    color: $main-color;
  }

  to {
    color: #fff;
  }
}

@keyframes toShowWhite {
  from {
    color: $main-color;
    opacity: 0;
  }

  to {
    color: $main-color;
    opacity: 1;
  }
}

.main-container {
  display: block;
  flex: auto;
  color: $main-color;
  flex-direction: row;

  .indexItem:nth-child(1) {
    .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards;

      .indexIcon {
        animation: toHide 0.5s forwards;
      }

      .indexIconUp,
      p {
        animation: toShowWhite 0.5s forwards;
      }

      h2 {
        color: $main-color;
      }
    }
  }

  .indexItem:nth-child(2) {
    .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards;

      .indexIcon {
        animation: toHide 0.5s forwards;
      }

      .indexIconUp,
      p {
        animation: toShowWhite 0.5s forwards;
      }

      h2 {
        color: $main-color;
      }
    }
  }

  .indexItem:nth-child(3) {
    .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards;

      .indexIcon {
        animation: toHide 0.5s forwards;
      }

      .indexIconUp,
      p {
        animation: toShowWhite 0.5s forwards;
      }

      h2 {
        color: $main-color;
      }
    }
  }

  .indexItem:nth-child(4) {
    .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards;

      .indexIcon {
        animation: toHide 0.5s forwards;
      }

      .indexIconUp,
      p {
        animation: toShowWhite 0.5s forwards;
      }

      h2 {
        color: $main-color;
      }
    }
  }

  .indexItem:nth-child(5) {
    .indexCard:hover {
      background-color: rgba(242, 242, 242, 0.64);
      animation: rise 0.5s forwards;

      .indexIcon {
        animation: toHide 0.5s forwards;
      }

      .indexIconUp,
      p {
        animation: toShowWhite 0.5s forwards;
      }

      h2 {
        color: $main-color;
      }
    }
  }

  .indexItem {
    width: 20%;

    .ant-card-bordered {
      border: none;
    }

    .indexCard {
      width: 100%;
      height: 560px;

      .ant-card-body {
        width: 100%;
        padding: 0;

        // padding: 186px 0 100px;
        .indexIcon,
        .indexIconUp {
          font-size: 96px;
          margin-bottom: 68px;
          color: #fff;
        }

        .indexIconUp {
          opacity: 0;
          margin-left: -96px;
        }
      }
    }

    h2 {
      text-align: center;
      color: $main-color;
    }

    p {
      opacity: 0;
      margin-bottom: 0.5em;
    }
  }

  .main-standard {
    padding: {
      left: 24px !important;
      right: 24px !important;
    }
  }
}
.itemContent {
  padding-left: 1em;
  padding-right: 1em;
  height: 0;
}
