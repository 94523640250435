.LeftMenu {
  width: 234px !important;
  border-left: 1px solid #e8e8e8 !important;
  // border-top: 1px solid #e8e8e8 !important;
  height: 100% !important;
  .headerItem {
    border: 0 !important;
    padding: 0 !important;
    margin: 0;
    height: 221px !important;
    .headerCard {
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      border-right: 1px solid #e8e8e8 !important;
      .ant-card-body {
        width: 100%;
        padding: 0 !important;
        background-color: #fff;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .headerIcon {
          display: block;
          margin-left: auto;
          margin-right: auto;
          svg {
            width: 88px;
            height: 88px;
          }
        }
      }
    }
  }
  .icon-formenu {
    font-size: 1.2rem;
  }
  .ant-menu-submenu-title,
  .ant-menu-item {
    padding-left: 56px !important;
    display: flex;
    align-items: center;
  }
  .ant-menu-item-selected::after {
    border-left: 1px solid #e8e8e8 !important;
  }
  .ant-menu-item-selected:hover {
    color: #00a1e6;
  }
  .ant-menu-item-selected {
    color: #00a1e6;
    padding-left: 6px !important;
  }
  .ant-menu-submenu-title:hover {
    color: #00a1e6;
  }
  .ant-menu-item-selected {
    a {
      color: #00a1e6;
    }
  }
  .ant-menu-item-active {
    color: #00a1e6;
  }
  .ant-menu-item-selected:before {
    // padding-left: 0 !important;
    content: '';
    width: 36px;
    height: 4px;
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 5px;
    background-color: $main-color;
  }
  .ant-menu-item::after {
    border-right: 1px solid #e8e8e8 !important;
  }
  .headerItem:after {
    border-right: 1px solid #e8e8e8 !important;
  }
  .headerItem {
    padding-left: 0 !important;
  }
}
