.routeHeaderContainer {
  height: 96px;
  padding-left: 24px;
  background-color: '#FFFFFF';
}
.router-header-hidden-breadcrumb {
  height: 64px;
  padding-left: 24px;
  background-color: '#FFFFFF';
}
.router-breadcrumb-container {
  height: 32px;
  display: flex;
  align-items: center;
  .ant-breadcrumb {
    a {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
.router-title-container {
  height: 64px;
  display: flex;
  align-items: center;
}
