.seqs-modal-component {
  .sequences-uuid-table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
  .ant-modal-header {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);

    .ant-modal-title {
      display: flex;
      justify-content: center;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-header {
    border-top-right-radius: 16px;
    border-bottom: 1px solid white;
    border-top-left-radius: 16px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
  }

  .ant-modal-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    .ant-table {
      min-height: 300px;
    }
  }

  .ant-divider-inner-text {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: 1px solid white;
  }
  .ant-btn {
    border: none;
    font-weight: bold;
    width: 96px;
  }
  .ant-btn-no {
    border: none;
    font-weight: bold;
    width: 96px;
    border: 1px solid rgba(214, 214, 214, 0.64) !important;
  }
  .ant-table-thead > tr > th {
    background: white;
  }
}
.list-type-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  border: 1px solid rgba(214, 214, 214, 0.64);
}

.ant-radio-group {
  .ant-btn {
    border: none;
    font-weight: bold;
  }

  .ant-radio-button-wrapper {
    border: none;
    border-radius: 16px;
    outline: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: white !important;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ffffff;
  }
}
