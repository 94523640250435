.page-container {
  margin-bottom: 71px;
  background-color: white;

  .table-pagination {
    text-align: center;
    margin-top: 48px;
  }
}

.isshowpwd {
  * {
    font-size: 18px;
  }

  margin-left: 8px;
  position: relative;
  top: 3px;
}

.custom-card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  flex: 1;

  .ant-btn[disabled] {
    color: $line-color;
    border: none;
    background-color: white;
  }
}

.custom-card-button-text {
  color: $main-color;
  border: none;

  &:hover,
  &:active,
  &:focus {
    color: white !important;
  }
}



.no-data-container {
  display: 'flex';
  justify-content: 'center';
  height: '169px';
  // border-bottom: 1px solid rgba($line-color, 0.64);
}