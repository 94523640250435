.index-layout {
  .card-right {
    .ant-card-body {
      padding-left: 0 !important;
    }
  }
  .down {
    color: #fff;
    font-size: 20px;
    margin-top: 2px;
  }
  .more-link-list-index {
    .more-link {
      color: #ffffff !important;
    }
  }
  #fullbg {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
  }
  .Menu {
    height: 64px;

    li {
      height: 100%;
    }
  }

  .ant-menu-item {
    line-height: 64px;

    padding: {
      top: 0 !important;
      bottom: 0 !important;
    }
  }
  .ant-menu-submenu {
    border-bottom: none !important;
  }

  .ant-menu-horizontal {
    border-bottom: 0 !important;
  }
  .menu-right-item:active {
    background-color: #fff;
    color: $main-color;
  }

  .ant-menu-item-selected {
    border: none !important;
  }

  .pholder {
    position: absolute;
    height: 64px;
    left: 16em !important;
    top: -2em;
  }

  .standard-plus {
    padding: {
      left: 24px !important;
      right: 24px !important;
    }
  }

  .broCenter {
    display: flex;
    align-items: center;
  }

  .ant-dropdown-placement-bottomRight {
    .ant-dropdown-menu {
      margin: {
        right: 60px !important;
        top: 15px;
      }
    }
  }

  .bell-group {
    padding: {
      left: 3em;
      right: 3em;
    }
  }

  .leftInItem {
    animation: leftIn 1s forwards;

    & > div {
      letter-spacing: 16px;
      font-size: 18px;
      font-weight: 600;
    }

    & > button {
      background: transparent;
      color: rgba(white, 0.85);
      border-color: rgba(white, 0.85);
      margin-top: 20px;

      &:active,
      &:hover {
        color: rgba(white, 1);
        border-color: rgba(white, 1);
      }
    }
  }

  .index-bg {
    background: #000 url('/indexBg.svg') no-repeat center;
    background-size: cover;
    height: 600px;
  }

  .img-row {
    height: 143px;

    img {
      margin-top: -110px;
      height: 465px;
      width: 841px;
      animation: toLong 1s forwards;
    }
  }
  .header-index {
    // background-color: #000;
    .unreadFalse {
      color: rgba(0, 0, 0, 0.45);
    }

    .unreadTrue {
      color: rgba(0, 0, 0, 1);
    }

    .bell-btn {
      color: #fff;
    }

    .logo1 {
      color: #fff;
    }
    padding: 0;
    height: 64px;
    min-height: 64px;

    .divideLine {
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, rgba(white, 0), #fff, rgba(white, 0));
    }

    background: transparent;
    padding: 0 10px;

    .userHeader {
      float: right;
      margin-top: 12px;
      cursor: pointer;
    }

    .Menu {
      background: transparent;

      .ant-menu-submenu-title,
      .ant-menu-item {
        padding: 0.96vh;
        padding-left: 16px;
        padding-right: 16px;
      }

      .ant-menu-submenu {
        padding: 0;
      }

      > .ant-menu-item {
        color: rgba(white, 0.85);
      }

      .ant-menu-item-selected,
      .ant-menu-submenu-title:hover,
      .ant-menu-item:hover {
        color: rgba(white, 1);
      }

      .ant-menu-item-selected {
        border-top: 1px solid #fff;
      }
      .medataicon-logo1 {
        font-size: 24px;
      }
    }

    .bell-btn {
      font-size: 20px;
      line-height: 20px;
    }

    sup {
      top: 5px;
      right: 46px;
    }

    .right-top {
      width: 326px !important;
    }

    .logo1-col {
      line-height: 27px;
      display: flex;
      // width: 326px;

      button {
        background: transparent;
        color: #fff;
        font-size: 12px;
        height: 24px;
        width: 72px;
        padding: 0;
      }

      // justify-content: center;
      .logo1 {
        font-size: 64px;

        svg {
          width: 1.5em;
        }
      }
    }

    .user-header {
      font-size: 1.85em;

      svg {
        width: 1em;
        height: 1em;
      }
    }

    .username {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 60px !important;
    }

    .user {
      float: left;
      color: #fff;
    }

    .down {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.main-layout {
  .down {
    color: $main-color;
    font-size: 20px;
    margin-top: 2px;
  }
  background: transparent;
  color: rgba($color: #000000, $alpha: 0.64) !important;
  .ant-dropdown-placement-bottomRight {
    .ant-dropdown-menu {
      margin: {
        right: 60px !important;
        top: 15px;
      }
    }
  }
  .ant-dropdown-trigger {
    i {
      color: $main-color;
    }
  }
  .pholder {
    position: absolute;
    height: 64px;
    left: 16em !important;
    top: -2em;
  }
  .Menu {
    height: 64px;

    li {
      height: 100%;
    }
  }

  .ant-menu-item {
    line-height: 64px;

    padding: {
      top: 0 !important;
      bottom: 0 !important;
    }
  }
  .ant-menu-submenu {
    border-bottom: none !important;
  }
  header {
    height: 64px;
  }

  .ant-menu-horizontal {
    border-bottom: 0 !important;
  }
  .menu-right-item:active {
    background-color: #fff;
    color: $main-color;
  }
  .ant-menu-item-selected {
    border: none !important;
  }
  .broCenter {
    display: flex;
    align-items: center;
    /*   padding-left: 26px; */
    // justify-content: center;
  }

  #fullbg {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
  }

  .leftInItem {
    animation: leftIn 1s forwards;
  }

  .unreadFalse {
    color: rgba(0, 0, 0, 0.45);
  }

  .unreadTrue {
    color: rgba(0, 0, 0, 1);
  }
  .logo1 {
    color: $main-color;
    cursor: pointer;
  }

  .img-row {
    img {
      margin-top: -110px;
      height: 465px;
      animation: toLong 1s forwards;
    }
  }
  header {
    // background-color: #000;
    height: 64px;
    min-height: 64px;
    background: #ffffff;

    .down {
      color: $main-color !important;
    }

    .ant-menu-item-selected {
      border-bottom: 0;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-title:hover,
    .ant-menu-item:hover {
      border-bottom: none;
      // border-top: 2px solid $main-color;
      color: $main-color;
    }

    .ant-menu-item-selected,
    .ant-menu-submenu-title:active,
    .ant-menu-item:active {
      border-bottom: none;
      // border-top: 2px solid $main-color;
      color: $main-color;
    }

    .divideLine {
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
    }

    padding: 0 10px;

    .userHeader {
      float: right;
      margin-top: 12px;
      cursor: pointer;
    }

    .Menu {
      background: transparent;

      .ant-menu-submenu-title,
      .ant-menu-item {
        padding: 0.96vh;
        padding-left: 16px;
        padding-right: 16px;
        height: 100%;
      }

      color: $main-color;

      li {
        height: 64px;
        padding: 14px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .bell-btn {
      font-size: 20px;
      line-height: 20px;
      color: #000;
    }

    sup {
      top: 5px;
      right: 46px;
    }

    .logo1-col {
      line-height: 27px;
      display: flex;
      button {
        background: transparent;
        color: #000;
        font-size: 12px;
        height: 24px;
        width: 72px;
        padding: 0;
        border-color: $main-color;

        i {
          color: $main-color;
        }
      }

      // justify-content: center;
      .logo1 {
        font-size: 64px;

        svg {
          width: 1.5em;
        }
      }
    }

    .user-header {
      font-size: 1.85em;

      svg {
        width: 1em;
        height: 1em;
      }
    }
    .username {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 60px !important;
    }
    .user {
      float: left;
      color: #000;
    }

    .user-down {
      color: $main-color;
    }

    .down {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: 2px;
      font-size: 20px;
      color: #d6d6d6;
      margin-left: 5px;
    }
  }
  .more-link-list-index {
    .more-link {
      color: #000 !important;
    }
  }
  .standard {
    border-bottom: 1px solid #e8e8e8;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
/* --------------------------------------------------------- */

@keyframes rightIn {
  from {
    margin-left: 15em;
  }

  to {
    margin-left: 0;
  }
}

@keyframes toLong {
  from {
    width: 0;
    margin-left: -15em;
    opacity: 0;
  }

  to {
    width: 841px;
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes leftIn {
  from {
    margin-left: -15em;
    opacity: 0;
  }

  to {
    margin-left: 0;
    opacity: 1;
  }
}

.index-left-ul {
  list-style-type: none;
  font-size: 14px;
  width: 22em;

  .index-left-li {
    margin-top: 20px;
    text-indent: -32px;
    margin-left: -8px;
  }

  li:before {
    content: '■\2002\2002\2002';
  }
}

.index-right-ul {
  list-style-type: none;
  padding-left: 0;
  animation: rightIn 1s forwards;

  li {
    margin-top: 39px;
    // width: 90px;
    // margin-right: 0;
    float: right;

    .ant-card {
      border: none;
      width: 165px;
      height: 84px;
      background: transparent;
      color: #fff;
      background: transparent url('/border.svg') no-repeat;
      background-position: center;
      background-size: 100% 100%;

      .ant-card-body {
        padding: 0;
        padding-top: 0.5em;

        h3 {
          color: #fff;
          // font-weight: bold;
          text-align: center;
          font-size: 18px;
          margin: 0;
        }

        p {
          text-align: center;
          font-size: 30px;
        }
      }
    }
  }
}

.fixed-menu {
  position: fixed;
  // width: 40vw;
  right: 0;
  top: 70vh;
  z-index: 999;

  .fixed-item {
    background: #f2f2f2 !important;
    height: 36px;
    width: 36px;
    color: $main-color;
    padding: 0;
    margin-bottom: 4px;
    margin-top: 0px;

    i {
      color: $main-color;
      font-size: 36px !important;
      margin: 0;

      svg {
        width: 24px;
      }
    }
  }

  .right-menus-active {
    .icon-container-active {
      i {
        color: #fff !important;
        font-size: 24px !important;
        margin: 0;

        svg {
          width: 24px;
        }
      }
    }
  }

  .right-menus,
  .right-menus-active {
    i {
      color: $main-color !important;
      font-size: 24px !important;
      margin: 0;

      svg {
        width: 24px;
      }
    }
  }

  .fixed-item:hover {
    background: $main-color !important;

    i {
      color: #fff !important;
      font-size: 24px !important;

      svg {
        width: 24px;
      }
    }
  }
}

// @keyframes rota {
//   from {
//     transform: rotateY(0deg);
//   }
//   to {
//     transform: rotateY(360deg);
//   }
// }
// .card-right:hover {
//   animation: rota 0.5s forwards;
// }
.footerCenter {
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.indexMenuContent {
  height: 100%;
  min-width: 1170px;
  border-bottom: none;
  .indexMenuContentNext {
    display: flex;
    align-items: center;
    width: 100%;
    .logo1-col {
      top: -8px;
    }
    .sideMenu {
      margin-right: 1em;
    }
  }
}

@media (min-width: 1200px) {
  .indexMenuContent {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .indexMenuContent {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .indexMenuContent {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
  }
}

#mainContent {
  min-height: (86.17vh);
}

.down {
  margin-left: 0.2em !important;
  margin-right: 0.2em !important;
}

.ant-layout-footer {
  height: 7vh;
  min-height: 64px;
  padding: 0;
}

.arrow-up {
  z-index: 10;
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: white;
  border-style: solid;
  border-width: 4.24264069px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  margin: 0;
  padding: 0;
  border-top: 1px solid $line-color;
  border-left: 1px solid $line-color;
}
.head-right {
  top: 0 !important;
}
.user-msg {
  padding-right: 10px;
  left: 14px;
}
.messageListDown {
  margin: {
    top: -3px;
    left: 1px;
  }

  background-color: #fff;
  border-radius: 4px;
  left: 200px;

  .ant-list-item-meta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-top-bar {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }

  .ant-spin-nested-loading {
    max-height: 300px;
    overflow: auto;
  }

  .ant-list-item-meta-content {
    h4 {
      width: 238px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .toRead {
    cursor: pointer;
  }

  .ant-tabs-bar {
    margin: 0;
    border-bottom: none;
  }

  .ant-tabs-nav-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.messageList {
  margin-left: -0.7em;
  margin-right: 1em;
}
.hide-box {
  float: left;
  height: 17px;
  width: 75px;
}
