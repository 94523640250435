// block居中
.blockCenter {
  display: block;

  margin: {
    left: auto;
    right: auto;
  }
}

.Introduction {
  padding-bottom: 1em;

  .divider {
    top: 85px;
    position: relative;
    z-index: 1;
    margin-left: 5%;
    // margin-right: 2px;
    min-width: 90%;
    width: 90%;
  }

  .ant-carousel {
    .slick-slide {
      height: 100%;
      background: #fff;
      overflow: hidden;

      .description {
        margin-top: 11em;
        padding: 1em;
        padding-top: 6em;
        height: 25em;
        margin-bottom: 1em;
      }
    }
  }

  .ant-carousel .slick-slide h3 {
    color: #000;
  }

  .ant-carousel {
    .slick-dots:before {
      content: '';
      position: absolute;
      left: 5%;
      top: -2px;
      bottom: 0;
      right: auto;
      height: 2px;
      width: 90%;
      background-color: #d6d6d6;
    }

    .slick-dots {
      // border-top: 2px solid #d6d6d6;

      top: 33%;
      margin-bottom: -8%;

      li {
        button {
          width: 64px;
          height: 54px;
          opacity: 1;
          margin-top: -27px;
          margin-bottom: 12px;
          margin-left: 0;
          margin-right: 0;
        }

        z-index: 8;
      }

      li:nth-child(1) {
        width: 22%;

        button {
          background-image: url('/resource/intro1.svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(1).slick-active {
        button {
          background-image: url('/resource/intro1\(1\).svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(1):after {
        content: '数据管理';
        position: absolute;
        left: 0%;
      }

      li:nth-child(1).slick-active:after {
        content: '数据管理';
        color: $main-color;
        font-weight: bold;
      }

      li:nth-child(2) {
        width: 22%;

        button {
          background-image: url('/resource/intro2.svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(2).slick-active {
        button {
          background-image: url('/resource/intro2\(1\).svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(2):after {
        content: '数据检索';
        position: absolute;
        left: 0%;
      }

      li:nth-child(2).slick-active:after {
        content: '数据检索';
        color: $main-color;
        font-weight: bold;
      }

      li:nth-child(3) {
        width: 22%;

        button {
          background-image: url('/resource/intro3.svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(3).slick-active {
        button {
          background-image: url('/resource/intro3\(1\).svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(3):after {
        content: '数据使用';
        position: absolute;
        left: 0%;
      }

      li:nth-child(3).slick-active:after {
        content: '数据使用';
        color: $main-color;
        font-weight: bold;
      }

      li:nth-child(4) {
        width: 22%;

        button {
          background-image: url('/resource/intro4.svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(4).slick-active {
        button {
          background-image: url('/resource/intro4\(1\).svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(4):after {
        content: '安全盒';
        position: absolute;
        left: 3.5%;
      }

      li:nth-child(4).slick-active:after {
        content: '安全盒';
        color: $main-color;
        font-weight: bold;
      }

      li:nth-child(5) {
        button {
          background-image: url('/resource/intro5.svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(5).slick-active {
        button {
          background-image: url('/resource/intro5\(1\).svg');
          background-size: 100% 100%;
          background-color: transparent;
        }
      }

      li:nth-child(5):after {
        content: '下载中心';
      }

      li:nth-child(5).slick-active:after {
        content: '下载中心';
        color: $main-color;
        font-weight: bold;
      }

      .slick-active {
        button {
          width: 64px;
          height: 54px;
        }
      }
    }
  }
}

main {
  display: block;
}