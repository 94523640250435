.DescriptionItem {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);

  .short {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.65);
  }

  .long {
    margin-right: 8px;
    display: inline-block;
    color: rgba(0, 0, 0, 0.65);
  }

  .dataDetailsTitle {
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }

  .dataDetailsInfo {
    margin-bottom: 16px;
  }
}
