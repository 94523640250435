.member-drawer {
  .ant-form-item {
    margin-bottom: 0px;
  }

  .input-container {
    display: flex;
    justify-content: 'center';
  }
  .ant-input {
    border: none;
    border-bottom: 1px solid rgba(214, 214, 214, 0.64);
    border-radius: 0px;
  }
  .ant-input:focus {
    box-shadow: none;
  }
  .ant-drawer-body {
    padding: 0px;
  }
}

.passward-input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  height: 32px;
}
.selected-item {
  width: 156px;
}

.content-body {
  padding: 24px;

  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
}

.m-drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
}

.action-btn {
  width: 96px;
  height: 32px;
}

.role-edit-container {
  padding: 0px 24px 0px 24px;
  border-bottom: 1px solid rgba(214, 214, 214, 1);
}
.edit-account-mobile-title {
  font-weight: 'bold';
  color: 'rgba(0,0,0,0.64)';
  font-weight: bold;
  width: 65px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
}
