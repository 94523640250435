.tablecard {
  margin-top: 16px;

  .tablecard-smalltitle {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-card-body {
    padding: 0 24px;
  }

  .tablecard-title {
    height: 16px;
    line-height: 16px;

    * {
      font-size: 12px;
    }

    .tablecard-status {
      float: right;
      margin-right: 0;
    }

    .success {
      color: #52c41a;
    }
  }

  .ant-card-head {
    background-color: #fafafa;
  }

  .tablecard-info-title-msg {
    margin-right: 32px;
    color: rgba(0, 0, 0, 0.65);

    span {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .tablecard-info-title {
    height: 64px;
    line-height: 64px;

    .left {
      font-size: 18px;
      font-weight: bold;
    }

    .right {
      margin-right: 0;
      float: right;
    }
  }

  .tablecard-info-item {
    height: 32px;
    line-height: 32px;
    margin-bottom: 16px;

    .last {
      margin-right: 0;
      float: right;
    }

    label {
      position: relative;
      margin-right: 116px;
    }

    label:after {
      content: '';
      position: absolute;
      top: 50%;
      background: #d6d6d6;
      width: 112px;
      height: 1px;
      margin-left: 8px;
    }

    .laststatus {
      margin-right: 0px;
    }

    .laststatus:after {
      content: '';
      position: absolute;
      top: 50%;
      background: #d6d6d6;
      width: 0px;
      height: 1px;
      margin-left: 8px;
    }

    .fix {
      color: #00a1e6;
    }

    .unfix {
      color: #d6d6d6;
    }

    .statusfont {
      * {
        font-size: 16px;
      }
    }
  }

  .btngroup {
    width: 1122px;

    height: 64px;
    line-height: 64px;
    margin-top: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .ant-divider-horizontal {
      margin-top: 0px;
      margin-bottom: 0px;
      opacity: 0.36;
    }

    .btndisable {
      display: block;
      float: left;
      text-align: center;
      color: #d6d6d6;
    }

    .btnactive {
      display: block;
      float: left;
      text-align: center;
    }

    .btnactive:hover {
      background-color: #00a1e6;
      color: #ffffff;
    }

    .btnactive:active {
      background-color: rgba(0, 161, 230, 0.8);
    }

    .btnline {
      float: left;
      height: 34px;
    }

    .font {
      * {
        font-size: 24px;
        margin-right: 16px;
        position: relative;
        top: 4px;
      }
    }
  }
}

.tablecard:hover {
  border-color: rgba(0, 161, 230, 0.64);
}
