.grade-table-header {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
  padding-left: 24px;
  padding-top: 16px;
  background-color: #fafafa;
  // opacity: 0.67;
}
.select-form {
  width: 156px;
  padding-left: 10px;
}
.normal-search-button {
  margin-left: 64px;
  width: 96px;
}
.header-line {
  padding-left: 24px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  .all-score {
    color: $main-color;
    margin-right: 60px;
    font-weight: bold;
    font-size: 24px;
    margin-left: 8px;
  }
  .score-plus {
    color: $status-green;
    margin-right: 60px;
    margin-left: 8px;
  }
  .score-minus {
    color: $status-red;
    margin-right: 60px;
    margin-left: 8px;
  }
}
.grade-drawer {
  .ant-drawer-body {
    padding: 24px 0px 24px 0px;
  }
  .grade-drawer-content {
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #e8e8e8;
  }
}
.ant-table-body {
  margin: 0;
}
.ant-table-thead {
  th {
    padding: 8px 16px;
  }
}
.ant-table-tbody {
  td {
    padding: 14px 16px;
  }
}

.table-header {
  padding-bottom: 16px;

  padding-left: 24px;
  padding-top: 16px;
  background-color: white;
  margin-top: 10px;
  border: 1px solid #eff2f5;
  // opacity: 0.67;
}
.ant-table-thead > tr > th {
  background-color: white;
}
.ant-table-thead > tr {
  // border: 1px solid rgba(214, 214, 214, 0.64);
  border-top: none;
}
.ant-table-small {
  border-top: none;
  border-left: 1px solid rgba(214, 214, 214, 0.64);
  border-right: 1px solid rgba(214, 214, 214, 0.64);
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  border-radius: 0px;
}
.status-plus {
  color: $status-green;
}
.status-minus {
  color: $status-red;
}
