@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.sci-task-container .project-message-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;
  color: black;
  margin-top: 18px; }

.sci-task-container .project-discription-area .ant-input {
  border-radius: 8px; }

.sci-task-container .dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #00a1e6;
  margin-right: 7px; }

.sci-task-container .new-project-header-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center; }

.sci-task-container .project-input-title {
  display: flex;
  align-items: center;
  width: 75px;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  padding-right: 10px; }

.sci-task-container .project-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  width: 400px;
  height: 24px;
  border-radius: 0px; }

.sci-task-container .ant-form-item {
  margin-bottom: 0px; }

.sci-task-container .ant-table-thead > tr > th {
  background: white;
  border-top: none; }

.sci-task-container .ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid #e8e8e8; }

.sci-task-container .ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid #e8e8e8; }

.sci-task-container .complete-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6;
  height: 32px; }

.sci-task-container .project-data-picker-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  width: 422px;
  height: 32px;
  border-bottom: none; }
  .sci-task-container .project-data-picker-input .ant-input {
    border-radius: 16px; }

.sci-task-container .ant-select {
  margin-right: 10px; }

.sci-task-container .assigned-name {
  text-align: center; }

.sci-task-container .seq-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px; }
