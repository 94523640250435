.original-form-header {
  .ant-input-affix-wrapper .ant-input {
    border-radius: 16px;
  }
}

.searh_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.queryform-select1 {
  input {
    border: none;
  }

  border-radius: 20px;
  border: 1px solid #D6D6D6;
  overflow: hidden;
  position: relative;
  top: 9px;
}

.ant-form-item {
  align-items: center;
}