@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.scientific-data-manage-continer .header {
  display: flex; }
  .scientific-data-manage-continer .header .radio-more-type {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    border: 1px solid rgba(214, 214, 214, 0.64); }
    .scientific-data-manage-continer .header .radio-more-type .ant-btn {
      border: none;
      font-weight: bold; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #00a1e6;
      border-color: #00a1e6; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper {
      border: none;
      border-radius: 16px;
      outline: none !important; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper:first-child {
      border-radius: 16px; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper:last-child {
      border-radius: 16px; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper-checked::before {
      background-color: white !important; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: #ffffff; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper {
      color: #00a1e6; }
    .scientific-data-manage-continer .header .radio-more-type .ant-radio-button-wrapper-checked {
      color: white !important; }
  .scientific-data-manage-continer .header .list-type-left-container {
    flex: 1;
    display: flex;
    align-items: center; }

.scientific-data-manage-continer .original-form-header .ant-input-affix-wrapper .ant-input {
  border-radius: 16px; }

.scientific-data-manage-continer .table-header-container .ant-form-item {
  margin-bottom: 0px; }

.scientific-data-manage-continer .ant-table-thead > tr > th {
  border-top: none;
  background-color: white; }

.scientific-data-manage-continer .project-message-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;
  color: black;
  margin-top: 18px; }

.sequences-uuid-table {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px; }

.load-data-modal .ant-modal-header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px; }
  .load-data-modal .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.load-data-modal .ant-modal-content {
  border-radius: 16px; }

.load-data-modal .ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; }
  .load-data-modal .ant-modal-body .ant-table {
    min-height: 300px; }

.load-data-modal .ant-divider-inner-text {
  padding: 0; }

.load-data-modal .ant-modal-close .anticon {
  padding: 4px;
  border: 1px solid #d6d6d6;
  border-radius: 20px; }

.load-data-modal .ant-modal-header {
  border-bottom: none; }

.load-data-modal .ant-modal-footer {
  border-top: none; }

.load-data-modal .ant-modal-body {
  padding: 0px 24px 0px 24px; }

.load-data-modal .ant-table-thead > tr > th {
  background: white;
  border-top: 1px solid #e8e8e8; }

.load-data-modal .ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid rgba(214, 214, 214, 0.64); }

.load-data-modal .ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid rgba(214, 214, 214, 0.64); }

.load-data-modal .ant-table-tbody > tr.ant-table-row-selected td {
  background: white; }

.load-data-modal .ant-form-item {
  margin-bottom: 0px; }

.load-data-modal .original-input {
  width: 240px; }

.load-data-modal .table-header-container .ant-select {
  margin-right: 0px; }

.load-data-modal .ant-table-thead > tr > th {
  border-top: none;
  background-color: white; }

.load-data-modal .theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6;
  margin-bottom: 0px; }

.load-data-modal .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6;
  margin-bottom: 0px; }
  .load-data-modal .theme-normal-btn:hover, .load-data-modal .theme-normal-btn:active, .load-data-modal .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.load-data-modal .project-data-picker-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  width: 422px;
  height: 32px;
  border-bottom: none; }
  .load-data-modal .project-data-picker-input .ant-input {
    border-radius: 16px; }
