.reset-passward-container {
  padding: 24px 80px 24px 100px;
  .ant-modal-title {
    display: flex;
    justify-content: center;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }
  .psd-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 14px 24px 14px 24px;
  }
}
