@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.temporary-container .ant-table-thead > tr > th {
  background: white;
  border-top: 1px solid #e8e8e8; }

.temporary-container .ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid rgba(214, 214, 214, 0.64); }

.temporary-container .ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid rgba(214, 214, 214, 0.64); }

.temporary-container .ant-table-tbody > tr.ant-table-row-selected td {
  background: white; }

.temporary-container .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6;
  color: #00a1e6;
  border-color: #00a1e6; }
  .temporary-container .theme-normal-btn:hover, .temporary-container .theme-normal-btn:active, .temporary-container .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.tableHeader {
  display: 'flex';
  flex: 1;
  justify-content: 'space-between';
  align-items: 'center';
  flex-direction: 'row-reverse'; }

.table-row-action {
  cursor: pointer;
  color: #00a1e6; }

.updata-data-modal .ant-modal-header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px; }
  .updata-data-modal .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.updata-data-modal .ant-modal-content {
  border-radius: 16px; }

.updata-data-modal .ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; }
  .updata-data-modal .ant-modal-body .ant-table {
    min-height: 300px; }

.updata-data-modal .ant-divider-inner-text {
  padding: 0; }

.updata-data-modal .ant-select {
  width: 240px; }

.updata-data-modal .ant-modal-body {
  padding-bottom: 0px; }

.updata-data-modal .ant-modal-footer {
  padding: 24px; }

.updata-data-modal .ant-modal-close {
  right: 10px; }
  .updata-data-modal .ant-modal-close .anticon {
    padding: 4px;
    border: 1px solid #d6d6d6;
    border-radius: 20px; }

.updata-data-modal .theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.updata-data-modal .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6; }
  .updata-data-modal .theme-normal-btn:hover, .updata-data-modal .theme-normal-btn:active, .updata-data-modal .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.updata-data-modal .modal-content-view {
  padding: 32px; }

.updata-data-modal .form-item-container {
  align-items: center; }
