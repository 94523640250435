.GrantSet {
  .modal-title {
    text-align: center;
    margin-bottom: 0;
  }
  .ant-modal-body {
    padding: {
      top: 0;
    }
    .ant-form-item-label {
      margin: {
        bottom: 1em;
      }
    }
    .ant-checkbox-group-item {
      width: calc(33.3% - 8px);
      margin: {
        bottom: 1em;
      }
    }
  }
  .ant-checkbox-group-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px;
  }
  .checkboxGroup {
    width: 100%;
  }
}
