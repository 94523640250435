.memSel {
  .hide {
    display: none;
  }
  .ipt {
    background: none;
    outline: none !important;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-required::before {
    content: '' !important;
  }
}
.memsel-title {
  text-align: center;
}
.title {
  margin-bottom: 1rem;
}
