.memSel {
  .hide {
    display: none;
  }
  .ipt {
    background: none;
    outline: none !important;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-required::before {
    content: '' !important;
  }
}
.memsel-title {
  text-align: center;
}
.downMain {
  height: 60vh;
}
.pathForPJ {
  width: 48%;
  margin: {
    left: auto;
    right: auto;
  }
}
.seqNum {
  color: #00a1e6;
  font-size: 16px;
  margin: {
    left: 2px;
    right: 2px;
  }
}
.secondStep {
  .return-btn {
    color: black;
  }
}
.seriesInstanceUid {
  width: 200px;
}
.studyInstanceUid {
  width: 200px;
}
.close {
  color: #d0021b;
}
.open {
  color: #52c41a;
}
.whiteBg {
  background-color: #fff;
  color: #00a1e6;
}
.seqhead {
  color: rgba($color: #000, $alpha: 0.85);
}
