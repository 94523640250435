.standard-p {
  margin-left: 24px;
  margin-right: 24px;
}
.main {
  margin-top: 25px;
}
.Breadcrumb {
  float: right;
}
.ant-breadcrumb {
  a:hover {
    color: $main-color;
  }
}
.title {
  margin-top: 28px;
}
.title-content::before {
  content: '●  ';
  color: $main-color;
}
.new-mem {
  float: right;
}
.ipt {
  background: none;
  outline: none !important;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.editName {
  font-weight: 100;
}
.new-mem {
  height: 32px;
  width: 96px;
  margin-top: -8px;
}
.close {
  color: $status-red;
}
.open {
  color: $status-green;
}
.new-mem-bt {
  height: 32px;
  width: 96px;
  margin: {
    left: auto;
    right: auto;
  }
  display: block;
  background-color: #fff;
  color: $main-color;
}
.renderFormHeader {
  height: 5rem;
  display: flex;
  align-items: center;
  .table-header {
    width: 100%;
  }
}
.return-btn {
  color: #000;
}
.Breadcrumb {
  span {
    color: rgba($color: #000000, $alpha: 0.45);
  }
}
