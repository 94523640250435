.SwiperGuide {
  .title_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .lef {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
    border-right: 1px solid #D6D6D6;
    padding-right: 16px;
    position: absolute;
    right: 0;
  }

  .ti_b {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(208, 2, 27, 1);
    margin-right: 5px;
  }

  .un {
    width: 76px;
  }

  .swiper_box {
    text-align: center;
  }

  .footer_box {
    text-align: center;
    position: relative;

  }

  .footer_desc {
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    line-height: 22px;
    margin: 20px 0;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    .div {
      border-right: 1px solid #D6D6D6;
      border-left: 1px solid #D6D6D6;
      padding: 0 20px;
      margin: 0 20px;
    }
  }

  .swi_img {
    width: 600px !important;
    height: 344px;
  }

  .ant-modal-footer {
    border: none;
  }

  .ant-modal-header {
    border: none;
  }

  .ant-btn {
    width: 100px;
  }

  .cir_box {
    display: flex;
    justify-content: center;
    align-items: center;

    .cir_s {
      border: 1px solid rgba(214, 214, 214, 1);
      width: 14px;
      height: 14px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(2) {
        margin: 0 20px;
      }
    }

    .cir_b {
      width: 8px;
      height: 8px;
      background: rgba(0, 161, 230, 1);
      border-radius: 8px;
    }

    .sele_bor {
      border: 1px solid rgba(0, 161, 230, 1);
    }
  }

  .ren_box {
    position: absolute;
    color: #00A1E6;
    font-size: 16px;
    right: 16px;
    bottom: 37px;
  }

  .btn {
    margin-bottom: 35px;
    margin-top: 10px;
  }

  .headerIcon {
    color: #00A1E6;
  }
}