.member-manage {
  .task-header {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      border: none;
      font-weight: bold;
    }

    .ant-radio-button-wrapper {
      border: none;
      border-radius: 16px;
      padding-left: 30px;
      padding-right: 30px;
      outline: none !important;
    }

    .ant-radio-button-wrapper:first-child {
      border-radius: 16px;
    }
    .ant-radio-button-wrapper:last-child {
      border-radius: 16px;
    }
    .ant-radio-button-wrapper-checked::before {
      background-color: white !important;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: #ffffff;
    }
  }
  .ant-divider-inner-text {
    padding: 0px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-input-affix-wrapper .ant-input {
    border-radius: 16px;
  }
  .ant-table-thead > tr {
    border-bottom: 1px solid rgba(214, 214, 214, 0.32);
  }
  .ant-table-thead > tr > th {
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-left: 1px solid rgba(214, 214, 214, 0.32);
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-right: 1px solid rgba(214, 214, 214, 0.32);
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
.list-type-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  border: 1px solid rgba(214, 214, 214, 0.64);
}

.role-permissions {
  color: rgba(0, 0, 0, 0.64);
  padding-right: 10px;
  padding-left: 10px;
}
.left-border {
  border-left: 1px solid rgba(214, 214, 214, 1);
}
.role-permissions-more {
  padding-left: 10px;
  color: $main-color;
  cursor: pointer;
}
.status-green {
  color: rgba(82, 196, 26, 1);
}
.status-red {
  color: rgba(166, 32, 36, 1);
}
.memberAction {
  display: flex;
  align-items: center;
}
.toFlex {
  display: flex;
}
.btn {
  color: $main-color;
  cursor: pointer;
}
.toFlexCenter {
  display: flex;
  align-items: center;
}
.normal-search-button {
  border: 1px solid $main-color;
  color: $main-color;
  height: 32px;
  border-radius: 16px;
  padding-left: 31px;
  padding-right: 31px;
}
.btn-set {
  margin-left: 48px;
  margin-right: 64px;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }
}
.whitebackground {
  background-color: #ffffff;
}
.mt30 {
  margin-top: 30px;
}
