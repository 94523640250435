.sleOr {
  width: 276px;
  height: 32px;
  margin-left: 20px;
}

.Table {
  //   width: 100%;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #eff2f5;
}

.detailShow {
  margin-right: 10px;
  color: $main-color;
  font-size: 16px;
  cursor: pointer;
}

.constainer {
  height: 100%;
  width: 100%;
}

.ant-form-item-label {
  width: auto;
  padding-right: 8px;
  line-height: 32px;
}

.input-prefix {
  margin-left: 16px;
  background: $main-color;
  font-size: 16px;
  color: white;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 2px 2px 2px 2px;
}

.ant-drawer-title {
  color: $main-color;
}

.drawer-but-container {
  display: 'flex';
  justify-content: 'flex-end';
  border-top: 1px solid #e9e9e9;
  padding: 10px;
}

.drawer-order-detail-item {
  border-bottom: 1px dashed #e9e9e9;
  margin-bottom: 10px;
}

.ant-modal-title {
  display: flex;
  justify-content: center;
  color: $main-color;
  font-size: 16px;
}

.ant-modal-header {
  border-bottom: 1px solid $main-color;
}

.DataSearchForm {
  border: 1px solid rgba(214, 214, 214, 0.64);

  padding: {
    top: 16px;
    bottom: 16px;
    left: 24px !important;
    right: 24px !important;
  }

  .ant-form-item-label {
    line-height: 40px;
    padding-left: 0 !important;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    background-color: $main-color;
    color: white;
  }
}
