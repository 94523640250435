.table-header-container {
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  background-color: white;
  padding: 16px 24px;
  border: 1px solid rgba(214, 214, 214, 0.64);
  .ant-select {
    width: 156px;
    margin-right: 90px;
  }
  .ant-form-item {
    display: flex;
  }
}
