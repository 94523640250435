@import '~antd/dist/antd.css';

.Menu {
  min-width: 499px;

  .icon-formenu {
    font-size: 1.15em !important;
  }

  .top-item {
    padding: 0 14px;
    color: red;
    .ant-menu-submenu-title {
      padding: 0;
    }

    .top-item-line {
      height: 1px;
      width: 100px;

      background-color: #000000;
    }
  }
  ant-menu-item-selected {
    color: red;
  }
}
