@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.sci-wait-component {
  /* 上箭头 */ }
  .sci-wait-component .seq-column-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px; }
  .sci-wait-component .ant-form-item {
    margin-bottom: 0px; }
  .sci-wait-component .project-name-container .form-item-container {
    flex: 1; }
  .sci-wait-component .form-content .form-item-container {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-top: 25px; }
    .sci-wait-component .form-content .form-item-container input {
      width: 240px; }
    .sci-wait-component .form-content .form-item-container .ant-form-item {
      padding-left: 0 !important; }
      .sci-wait-component .form-content .form-item-container .ant-form-item .form-item-input {
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 16px;
        margin-left: 3.84px;
        width: 240px; }
    .sci-wait-component .form-content .form-item-container .selected-item {
      margin-left: 0; }
  .sci-wait-component .genetic-from-select-container {
    display: 'flex';
    margin-top: '26px';
    align-items: center; }
  .sci-wait-component .image-view-container .form-item-container {
    display: flex;
    align-items: center;
    margin-right: 30px; }
    .sci-wait-component .image-view-container .form-item-container .ant-form-item {
      padding-left: 0 !important; }
      .sci-wait-component .image-view-container .form-item-container .ant-form-item .form-item-input {
        border-bottom: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 0px;
        margin-left: 3.84px;
        width: 240px; }
    .sci-wait-component .image-view-container .form-item-container .selected-item {
      margin-left: 0; }
  .sci-wait-component .jiantou-before {
    display: block;
    content: '';
    border-width: 8px 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent #ff4d4d transparent;
    width: 10px;
    /* 定位 */ }
  .sci-wait-component .share-steps-container {
    padding-top: 0px;
    width: 700px; }
  .sci-wait-component .ant-steps-item-process .ant-steps-item-icon {
    background: #00a1e6;
    border-color: #00a1e6; }
  .sci-wait-component .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: #00a1e6;
    font-weight: normal; }
  .sci-wait-component .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #00a1e6; }
  .sci-wait-component .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #00a1e6; }
  .sci-wait-component .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    border-color: rgba(0, 0, 0, 0.64); }
  .sci-wait-component .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.64);
    border-color: rgba(0, 0, 0, 0.64); }
  .sci-wait-component .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.64); }
  .sci-wait-component .top {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 150px;
    top: -15px;
    z-index: 2; }
  .sci-wait-component .top-image {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 250px;
    top: -15px;
    z-index: 2; }
  .sci-wait-component .top-arrow1,
  .sci-wait-component .top-arrow2 {
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    /*兼容ie8-*/
    border-top: 6px transparent dashed;
    border-left: 10px transparent dashed;
    border-right: 10px transparent dashed;
    border-bottom: 10px white solid;
    overflow: hidden; }
  .sci-wait-component .top-arrow1 {
    border-bottom: 10px rgba(214, 214, 214, 0.64) solid; }
  .sci-wait-component .top-arrow2 {
    top: 1px;
    /*重要*/
    border-bottom: 10px white solid; }

.seqs-modal-component .ant-modal-footer {
  border-top: 1px solid rgba(217, 217, 217, 0.65); }
  .seqs-modal-component .ant-modal-footer .ant-btn {
    border: 1px solid rgba(217, 217, 217, 0.65); }

.seqs-modal-component .seq-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px; }
