#NoDataImg {
  .no-img {
    height: 475px;
    width: 479px;
    margin-top: 40px;
  }

  .no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .no-txt {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 500;
    // color: rgba(0, 161, 230, 1);
    margin-top: 20px;
  }

  .load {
    width: 100%;
    height: 80vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tran {
    transition: all 5s;
  }

  .none {
    @extend .load;
    display: none;
  }
}
