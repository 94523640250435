.operation-guide-top-title {
  font-size: 16px;
  color: $main-color;
  margin-top: 16px;
}
.operation-guide-second-title {
  font-size: 14px;
  color: #000000;
  margin-top: 16px;
  font-weight: bold;
}
.peration-guide-image {
  border: 2px solid #e6fcff;
  width: 536px;
}
.operation-guide-third-title {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  margin-top: 6px;
}

.FAQ-right-container {
  position: fixed;
  // border-left: 1px solid #d6d6d6;
  padding-left: 7px;
}
.FAQ-left-container {
  border-right: 1px solid #d6d6d6;
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}
.ant-spin-nested-loading {
  height: 100%;
}
.FAQ-answer-container {
  .ant-collapse-item-active {
    .ant-collapse-header {
      color: $main-color;
    }
  }

  .ant-collapse-header {
    font-weight: bolder;
    padding-left: 0px !important;
  }
  .lengthControl {
    width: 153px;
  }
}
.main-color {
  color: $main-color;
}
.black-color {
  color: #000;
}
.imgFullWidth {
  width: 100%;
}
