$main-color: #00a1e6;
.customPanelStyle {
  background: '#f7f7f7';
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}

.order-check-container {
  // border: 1px solid rgba(214, 214, 214, 1);
  padding: 0px 10px 0px 25px;
  margin-top: 10px;
  background-color: #fafafa;
}
.order-check-package-container {
  background-color: #fafafa;

  display: 'flex';
  flex-direction: 'column';
  padding: 16px 10px 0px 0px;
  .dataItem {
    margin-top: 8px;
  }
}

.order-check-box-container {
  border-left: 1px dashed #d6d6d6;

  padding: 0px 0px 0px 23px;
  .tableColumnItem {
    font-size: 14px;

    margin-bottom: 8px;
  }
  .ant-input,
  .ant-input-number {
    border-radius: 16px !important;
    border-color: $main-color !important;
  }
  .ant-select-selection {
    border-color: $main-color !important;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}
.order-check-collapse-container {
  .ant-collapse-item-active {
    .ant-collapse-header {
      font-weight: bold !important;
      padding-left: 24px !important;
      .ant-collapse-arrow {
        right: 16px !important;
        left: 0px;
        svg {
          float: right;
          transform: rotate(180deg) !important;
        }
      }
    }
  }
  .ant-collapse-item {
    .ant-collapse-header {
      font-weight: normal;
      padding-left: 24px !important;
      .ant-collapse-arrow {
        right: 16px !important;
        left: 0px;
        svg {
          width: 1.5em;
          height: 1.51em;
          float: right;
        }
      }
    }
  }
}

.order-check-right-container {
  display: 'flex';
  flex-direction: 'column';
  height: 100% !important;
  padding: 0px 10px 0px 24px;
  .rowItem {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.order-security-box {
  border-right: 10px solid white;
  padding-right: 24px;
}
