$theme-color: #00a1e6;

.hint-modal-component {
  .ant-modal-header {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);

    .ant-modal-title {
      display: flex;
      justify-content: center;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    height: 64px;
  }

  .ant-btn {
    border: none;
    font-weight: bold;
    width: 96px;
  }

  .ant-btn-no {
    border: none;
    font-weight: bold;
    width: 96px;
    border: 1px solid rgba(214, 214, 214, 0.64) !important;
  }

  .ant-radio-inner::after {
    left: 22%;
    background-color: $theme-color;
  }

  .ant-radio-inner {
    border-color: $theme-color;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $theme-color;
  }

  .row-action-item {
    width: 100px;
    background-color: red;
  }
}

.has-shared-data-component {
  .ant-table-body table tbody td:last-of-type {
    padding-right: 0px !important;
    width: 253px;
  }
}

/* .ant-notification-notice {
  top: 290px;
  right: 400px;
} */
.seqs-detail {
  top: 30%;
  left: 0;
  width: 40% !important;

  .detail-title {
    font-weight: bolder;
    text-align-last: justify;
    text-align: justify;
    width: 90px;

    &::after {
      content: ':';
    }
  }

  .detail-content {
    text-indent: 19px;
  }

  .detail_box {
    display: flex;
    align-items: center;
  }
}

.ant-dropdown-menu-item {
  color: rgb(0, 161, 230);
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}
