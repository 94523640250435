.input-password {
  .ant-input {
    border: none !important;
    border-bottom: 1px solid rgba(#d6d6d6, 0.64) !important;
    border-radius: 0px !important;
  }
  .ant-input:focus {
    box-shadow: none !important;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .passward-input {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid rgba(#d6d6d6, 0.64);
    height: 32px;
  }
}
