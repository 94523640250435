.PathSet {
  .modal-title {
    text-align: center;
    margin-bottom: 0;
  }
  .textAreaForm {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .explain {
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    dd {
      margin-left: 2.2em;
    }
    i {
      color: #faad14;
      margin-right: 0.2em;
    }
    span {
      text-indent: -0.3em;
      margin-left: -1em;
    }
  }
}
