@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.project-task-deatil-container .project-message-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;
  color: black;
  margin-top: 18px; }

.project-task-deatil-container .dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #00a1e6;
  margin-right: 7px; }

.project-task-deatil-container .new-project-header-title {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center; }

.project-task-deatil-container .project-input-title {
  display: flex;
  align-items: center;
  width: 75px;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  padding-right: 10px; }

.project-task-deatil-container .project-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  width: 400px;
  height: 24px; }

.project-task-deatil-container .ant-table-thead > tr > th {
  background: white;
  border-top: 1px solid #e8e8e8; }

.project-task-deatil-container .ant-table-thead > tr:first-child > th:first-child {
  border-left: 1px solid #e8e8e8; }

.project-task-deatil-container .ant-table-thead > tr:first-child > th:last-child {
  border-right: 1px solid #e8e8e8; }

.project-task-deatil-container .complete-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.project-task-deatil-container .project-data-picker-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  width: 422px;
  height: 32px;
  border-bottom: none; }
  .project-task-deatil-container .project-data-picker-input .ant-input {
    border-radius: 16px; }

.project-task-deatil-container .located-icon {
  font-size: 16px; }

.project-task-deatil-container .nowarp-elips {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px; }

.member-modal .ant-form-item {
  margin-bottom: 0px; }

.member-modal .ant-modal-header {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85); }
  .member-modal .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.member-modal .ant-modal-content {
  border-radius: 16px; }

.member-modal .ant-modal-header {
  border-top-right-radius: 16px;
  border-bottom: 1px solid white;
  border-top-left-radius: 16px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center; }

.member-modal .ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; }
  .member-modal .ant-modal-body .ant-table {
    min-height: 300px; }

.member-modal .ant-divider-inner-text {
  padding: 0; }

.member-modal .ant-modal-footer {
  border-top: 1px solid white; }

.member-modal .ant-btn {
  border: none;
  font-weight: bold;
  width: 96px; }

.member-modal .ant-btn-no {
  border: none;
  font-weight: bold;
  width: 96px;
  border: 1px solid rgba(214, 214, 214, 0.64) !important; }

.member-modal .ant-table-thead > tr > th {
  background: white; }
