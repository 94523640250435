.standard-p {
  margin-left: 24px;
  margin-right: 24px;
}
.main {
  margin-top: 25px;
}
.Breadcrumb {
  float: right;
}
.ant-breadcrumb {
  a:hover {
    color: $main-color;
  }
}
.title {
  margin-top: 28px;
}

.title-content::before {
  content: '●  ';
  color: $main-color;
}
.new-task {
  float: right;
  height: 32px;
  width: 96px;
  margin-top: -8px;
}
.underline {
  background: none;
  outline: none !important;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $line-color;
  &:focus {
    box-shadow: none;
  }
}
.editName {
  font-weight: 100;
}
.newLine {
  margin-top: 1rem;
}

.hide {
  display: none;
}
.ant-calendar-picker {
  .ant-input {
    border-radius: 16px;
  }
}
.ant-form-item-control {
  #username {
    border-radius: 16px;
  }
}
.new-fin {
  height: 32px;
  width: 96px;
  margin-top: -16px;
  float: right;
}
.seqArea {
  margin-top: 1rem;
}
.taskname {
  width: 100px;
}
.description {
  width: 250px;
}
.return-btn {
  color: rgba($color: #000000, $alpha: 1);
}
.Breadcrumb {
  span {
    color: rgba($color: #000000, $alpha: 0.45);
  }
}
