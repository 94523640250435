@charset "UTF-8";
.updata-modal-component {
  min-height: 278px; }
  .updata-modal-component .ant-modal-close-x {
    color: #00a1e6;
    margin-top: 3px; }
  .updata-modal-component .help {
    margin-right: 0.5rem; }
  .updata-modal-component .excel-div {
    margin-right: 1.5rem;
    height: 1rem; }
  .updata-modal-component .updata-modal-component .ant-modal-content {
    min-width: 880px; }
  .updata-modal-component .form-content .form-item-container {
    width: 50%;
    flex: inherit !important;
    padding-right: 1rem; }
    .updata-modal-component .form-content .form-item-container .ant-form-item {
      padding-left: 0 !important; }
      .updata-modal-component .form-content .form-item-container .ant-form-item .form-item-input {
        border: 1px solid rgba(217, 217, 217, 0.65);
        border-radius: 16px;
        margin-left: 3.84px;
        width: calc(100% - 3.84px); }
    .updata-modal-component .form-content .form-item-container .selected-item {
      margin-left: 0; }
  .updata-modal-component .excel-dlbtn {
    float: right !important; }
  .updata-modal-component .tit {
    align-items: center; }
  .updata-modal-component .ant-modal-content {
    border-radius: 16px; }
  .updata-modal-component .headerIcon {
    color: #00a1e6; }
  .updata-modal-component .ti_b {
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #d0021b;
    margin-right: 5px; }
  .updata-modal-component .action-container {
    border: 1px dashed #00a1e6;
    padding: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 300px; }
  .updata-modal-component .ant-modal-body {
    min-height: 126px;
    padding-left: 72px;
    padding-right: 72px;
    padding-top: 0px; }
  .updata-modal-component .ant-radio-wrapper-checked {
    color: #00a1e6; }
  .updata-modal-component .ant-radio-checked .ant-radio-inner {
    color: #00a1e6;
    border-color: #00a1e6; }
  .updata-modal-component .ant-radio-inner::after {
    background-color: #00a1e6;
    left: 28%; }
  .updata-modal-component .ant-modal-footer {
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .updata-modal-component .ant-form-item {
    margin-bottom: 0px; }
  .updata-modal-component .ant-upload-drag-icon {
    margin-bottom: 16px !important; }
  .updata-modal-component .ant-upload-text {
    font-size: 14px;
    margin-bottom: 7px; }
  .updata-modal-component .ant-upload-drag {
    width: 318px;
    height: 141px; }
  .updata-modal-component .action-btn {
    width: 96px; }
  .updata-modal-component .ant-modal-title {
    display: flex;
    justify-content: center; }
  .updata-modal-component .ant-modal-header {
    display: flex;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    justify-content: center;
    height: 63px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px; }
  .updata-modal-component .ant-modal-title {
    flex: 1; }
  .updata-modal-component .ant-select {
    width: 240px; }
  .updata-modal-component .ant-input:focus {
    box-shadow: none; }
  .updata-modal-component .top {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 140px;
    top: -15px;
    z-index: 2; }
  .updata-modal-component .top-image {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 242px;
    top: -15px;
    z-index: 2; }
  .updata-modal-component .top-arrow1,
  .updata-modal-component .top-arrow2 {
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    /*兼容ie8-*/
    border-top: 6px transparent dashed;
    border-left: 10px transparent dashed;
    border-right: 10px transparent dashed;
    border-bottom: 10px white solid;
    overflow: hidden; }
  .updata-modal-component .top-arrow1 {
    border-bottom: 10px rgba(214, 214, 214, 0.64) solid; }
  .updata-modal-component .top-arrow2 {
    top: 1px;
    /*重要*/
    border-bottom: 10px white solid; }
  .updata-modal-component .text-last {
    width: 75px;
    text-align-last: justify;
    text-align: justify;
    text-justify: distribute-all-lines; }

.project-item-title {
  font-weight: 'bold';
  color: 'rgba(0,0,0,0.64)';
  font-weight: bold;
  width: 75px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; }

.tooltip-custom {
  background: none; }
  .tooltip-custom div {
    background-color: none; }
    .tooltip-custom div div:first-child {
      background: none;
      border-top-color: rgba(0, 161, 230, 0.32); }
    .tooltip-custom div div:last-child {
      color: rgba(0, 0, 0, 0.64);
      background-color: white;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(0, 161, 230, 0.32); }

.optips {
  background-color: #fff; }

.optTips .ant-tooltip-content .ant-tooltip-arrow {
  display: none; }

.optTips .ant-tooltip-content .ant-tooltip-inner {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.65); }
  .optTips .ant-tooltip-content .ant-tooltip-inner h4 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
    line-height: 0.1rem; }
  .optTips .ant-tooltip-content .ant-tooltip-inner .ant-divider-horizontal {
    margin: 0; }

.ant-select-search__field__wrap .ant-select-search__field {
  border-radius: 16px; }

.hint-form-item-title {
  width: auto; }

.yp {
  display: flex; }
