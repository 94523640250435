@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.hint-modal-component .ant-modal-header {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85); }
  .hint-modal-component .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.hint-modal-component .ant-modal-content {
  border-radius: 16px; }

.hint-modal-component .ant-modal-body {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center; }

.hint-modal-component .modal-content-view {
  padding: 64px 104px;
  border-top: 1px solid rgba(214, 214, 214, 0.64);
  border-bottom: 1px solid rgba(214, 214, 214, 0.64); }

.hint-modal-component .ant-modal-header {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  height: 64px; }

.hint-modal-component .ant-btn {
  border: none;
  font-weight: bold;
  width: 96px; }

.hint-modal-component .ant-btn-no {
  border: none;
  font-weight: bold;
  width: 96px;
  border: 1px solid rgba(214, 214, 214, 0.64) !important; }

.hint-modal-component .ant-radio-inner::after {
  left: 22%;
  background-color: #00a1e6; }

.hint-modal-component .ant-radio-inner {
  border-color: #00a1e6; }

.hint-modal-component .ant-radio-wrapper:hover .ant-radio,
.hint-modal-component .ant-radio:hover .ant-radio-inner,
.hint-modal-component .ant-radio-input:focus + .ant-radio-inner {
  border-color: #00a1e6; }

.hint-modal-component .row-action-item {
  width: 100px;
  background-color: red; }

.sci-package-component .ant-table-body table tbody td:last-of-type {
  padding-right: 0px !important;
  width: 253px; }

.sci-package-component .table-row-action-container {
  display: flex;
  align-items: center;
  color: #00a1e6; }
  .sci-package-component .table-row-action-container .action {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
    border-left: 1px solid #d6d6d6;
    margin-left: 10px; }

/* .ant-notification-notice {
  top: 290px;
  right: 400px;
} */
.seqs-detail {
  top: 30%;
  left: 0;
  width: 40% !important; }
  .seqs-detail .detail-title {
    font-weight: bolder;
    text-align-last: justify;
    text-align: justify;
    width: 90px; }
    .seqs-detail .detail-title::after {
      content: ':'; }
  .seqs-detail .detail-content {
    text-indent: 19px; }
  .seqs-detail .detail_box {
    display: flex;
    align-items: center; }

.ant-dropdown-menu-item {
  color: #00a1e6; }

.ant-table-fixed {
  table-layout: fixed; }

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all; }

.load-pkg-modal .ant-modal-header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px; }
  .load-pkg-modal .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.load-pkg-modal .ant-modal-content {
  border-radius: 16px; }

.load-pkg-modal .ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; }
  .load-pkg-modal .ant-modal-body .ant-table {
    min-height: 300px; }

.load-pkg-modal .ant-divider-inner-text {
  padding: 0; }

.load-pkg-modal .modal-content-view {
  padding: 104px 24px;
  text-align: center; }

.load-pkg-modal .ant-modal-header {
  border-bottom: 1px solid rgba(214, 214, 214, 0.64); }

.load-pkg-modal .ant-modal-footer {
  padding: 24px;
  border-top: 1px solid rgba(214, 214, 214, 0.64); }

.load-pkg-modal .ant-modal-close {
  right: 10px; }
  .load-pkg-modal .ant-modal-close .anticon {
    padding: 4px;
    border: 1px solid #d6d6d6;
    border-radius: 20px; }

.load-pkg-modal .theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.load-pkg-modal .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6; }
  .load-pkg-modal .theme-normal-btn:hover, .load-pkg-modal .theme-normal-btn:active, .load-pkg-modal .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }
