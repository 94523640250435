.hospitalDetail {
  label {
    margin: 30px auto;
  }

  .projectdetailOpt {
    width: 340px;

    a {
      padding: 15px 15px;
    }

    a:hover {
      background-color: #00a1e6;
      color: #ffffff;
    }

    .optline {
      height: 32px;
    }
  }

  .projectDetailCard {
    .ant-card-body {
      padding-left: 0px;
    }
  }

  .projectDetailForm {
    background-color: #fafafa;
    height: 64px;
    line-height: 64px;
    padding-top: 12px;
    border-bottom: 1px #e9e9e9 solid;
  }
}

.detectionLog {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header {
    background-color: #f2f2f2;
  }

  .logList {
    padding: 0px 10px 0px 24px;
    height: 64px;
    width: 100%;
    border-bottom: 1px rgba($color: #bbbbbb, $alpha: 0.45) solid;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5px;
  }
}
.lineContainer {
  display: flex;
  align-items: center;
  width: 100px;
  margin: 0px 10px 0 10px;
  height: 64px;
}
