@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.sec-box-card {
  height: 318px;
  width: 544px;
  border: 1px solid #f1f1f1;
  color: rgba(0, 0, 0, 0.64); }
  .sec-box-card .header {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    background: #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    height: 40px;
    font-size: 14px; }
  .sec-box-card .set-container {
    display: flex;
    margin: 16px; }
  .sec-box-card .theme-normal-btn {
    padding-left: 24px;
    padding-right: 24px;
    color: #00a1e6;
    background: white;
    border: 1px solid #00a1e6; }
    .sec-box-card .theme-normal-btn:hover, .sec-box-card .theme-normal-btn:active, .sec-box-card .theme-normal-btn:focus {
      color: #fff;
      background: #00a1e6; }
  .sec-box-card .nowarp-elips {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px; }
  .sec-box-card .securityBoxNumber {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px; }
