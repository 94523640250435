.mypharmacy-hospitallist {
    .ant-drawer-body {
        padding: 0;
    }

    th {
        height: 64px;
        line-height: 64px;
        padding-left: 24px;
    }

    tr {
        height: 64px;
        line-height: 64px;
        border-bottom: 1px rgba($color: #BBBBBB, $alpha: .45) solid;

    }

    td {
        padding-left: 24px;
    }
}


.myPharmacyCard {
    .ant-table-thead>tr>th {
        padding: 0 16px;
    }

    .ant-table-tbody>tr>td {
        padding: 0 16px;
    }

    .ant-table-thead>tr>th {
        background-color: #ffffff;
    }

    .ant-table-thead>tr {
        border: 1px solid rgba(214, 214, 214, 0.64);
    }
}