@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.sci-app-container .theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6;
  color: #00a1e6;
  border-color: #00a1e6; }
  .sci-app-container .theme-normal-btn:hover, .sci-app-container .theme-normal-btn:active, .sci-app-container .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.sci-app-container .dot {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #00a1e6;
  margin-right: 7px; }

.sci-app-container .project-list-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 24px; }

.sci-app-container .toos-container {
  margin-top: 24px; }
  .sci-app-container .toos-container .tool-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 302px;
    height: 240px;
    background: white;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 16px; }
