@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
#WaitShareComponent .project-name-container .form-item-container {
  flex: 1; }

#WaitShareComponent .form-content .form-item-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 25px; }
  #WaitShareComponent .form-content .form-item-container input {
    width: 240px; }
  #WaitShareComponent .form-content .form-item-container .ant-form-item {
    padding-left: 0 !important; }
    #WaitShareComponent .form-content .form-item-container .ant-form-item .form-item-input {
      border: 1px solid rgba(217, 217, 217, 0.65);
      border-radius: 16px;
      margin-left: 3.84px;
      width: 240px; }
  #WaitShareComponent .form-content .form-item-container .selected-item {
    margin-left: 0; }

#WaitShareComponent .genetic-from-select-container {
  display: 'flex';
  margin-top: '26px';
  align-items: center; }

#WaitShareComponent .image-view-container .form-item-container {
  display: flex;
  align-items: center;
  margin-right: 30px; }
  #WaitShareComponent .image-view-container .form-item-container .ant-form-item {
    padding-left: 0 !important; }
    #WaitShareComponent .image-view-container .form-item-container .ant-form-item .form-item-input {
      border-bottom: 1px solid rgba(217, 217, 217, 0.65);
      border-radius: 0px;
      margin-left: 3.84px;
      width: 240px; }
  #WaitShareComponent .image-view-container .form-item-container .selected-item {
    margin-left: 0; }

.seqs-modal-component .ant-modal-footer {
  border-top: 1px solid rgba(217, 217, 217, 0.65); }
  .seqs-modal-component .ant-modal-footer .ant-btn {
    border: 1px solid rgba(217, 217, 217, 0.65); }

.seqs-modal-component .seq-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px; }
