.dataSearch_box {
  width: 100%;
  display: flex;
}

.right_none {
  flex: 1;
  justify-content: center;
}

.marLfet {
  margin-left: 32px;
}

.right_box_co {
  flex: 1;
  margin-left: 16px;
  overflow-y: scroll;
  height: 100vh;
}

.check_fle {
  display: flex;
}

.right_box {
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
    /*隐藏滚动条*/
  }
}

.left_box {
  width: 280px;
  height: 100vh;
  overflow-y: scroll;
  border: 1px solid #d6d6d6;
  border-top: none;

  &::-webkit-scrollbar {
    display: none;
    /*隐藏滚动条*/
  }
}

.serch_box {
  display: flex;
  margin-top: 43px;
  align-items: center;
  justify-content: center;
}

.ipt {
  width: 440px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.ser_btn {
  width: 96px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  border: 1px solid rgba(0, 161, 230, 1);
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC;
  font-weight: 600;
  color: rgba(0, 161, 230, 1);
  line-height: 32px;
  margin-left: 32px;
}

.data_box {
  font-size: 14px;
  font-family: PingFangSC;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(214, 214, 214, 0.4);
  margin-top: 16px;

  &:hover {
    border: 1px solid rgba(0, 161, 230, 0.56);
  }

  .data_content {
    padding: 21px 16px;
    padding-bottom: 0;
  }

  .data_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .illness_name {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
  }
}

.white {
  background: white !important;
}

.filter_box {
  width: 100%;

  .filter_name {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.64);
    align-items: center;
    width: 276px;
    position: absolute;
    background: white;
    z-index: 100;
    .bor {
      width: 24px;
      height: 4px;
      background: rgba(0, 161, 230, 1);
      border-radius: 2px;
      margin: auto;
    }

    .sele {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
    }
  }

  .filter_item {
    padding: 20px 0;
    width: 50%;
    text-align: center;
  }

  .filter_item_box {
    background: #fafafa;

    .filter_con {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      i {
        font-size: 20px;
      }

      .fi_b_t {
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}

.checko_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
  padding: 5px 0;
  position: relative;

  &:hover {
    background: #e3f7ff;
  }

  .check {
    margin-right: 8px;
    width: 14px;
    height: 14px;
  }
}

.ant-rate {
  color: $main-color;
}

.data_detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;

  .data_detail_b {
    display: flex;
    align-items: center;

    .de_key {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.64);
    }

    .de_val {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.64);
      margin-left: 8px;
      width: 85px;
      @extend .lengthControl;
    }
  }
}

.popov {
  width: 210px;
  @extend .lengthControl;
}

.mo_b {
  font-size: 14px;
  font-family: PingFangSC;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;

  span {
    font-size: 24px;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(0, 161, 230, 1);
    padding-right: 10px;
  }
}

.da_ri {
  margin-right: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom_box {
  width: 100%;
  border-top: 0.5px solid rgba(214, 214, 214, 0.36);
  margin-top: 30px;

  .bottom_content {
    height: 64px;
    display: flex;
    align-items: center;
  }

  .icon_box {
    display: flex;
    align-items: center;
    color: rgba(0, 161, 230, 1);
    font-size: 24px;
    width: 50%;
    justify-content: center;

    .icon_na {
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      padding-left: 4px;
    }

    .icon_hover {
      display: flex;
      align-items: center;

      &:hover {
        background: #00a1e6;
        color: white !important;
        padding: 5px 20px;
        border-radius: 20px;
      }
    }
  }
}

.check_pad {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ipt {
  background: none;
  outline: none !important;

  &:focus {
    box-shadow: none;
  }
}

.rigBor {
  border-right: 1px solid rgba(214, 214, 214, 0.64);
}

.popover_style {
  background: white;
  color: #000000;
  box-shadow: 0px -3px 4px 3px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 161, 230, 0.32);
}
.tooltip-custom {
  background: white;
  color: black !important;

  div {
    background-color: none;

    div {
      &:first-child {
        background: none;
        border-top-color: rgba(0, 161, 230, 0.32);
      }

      &:last-child {
        color: rgba(0, 0, 0, 0.64);
        background-color: white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 161, 230, 0.32);
      }
    }
  }
}
