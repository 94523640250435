.hint-component {
  .ant-modal-footer {
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-modal-title {
    display: flex;
    justify-content: center;
    color: rgba(250, 173, 20, 1);
  }
  .ant-modal-body {
    color: rgba(0, 0, 0, 0.64);
    font-weight: bold;
  }
}
