.login-form-button {
  width: 240px;
  height: 36px;
  font-size: 14px;
  border-radius: 18px;
}
.toFlex {
  display: flex;
}
.inputBefore {
  background-color: $line-color;
  font-size: 30px;
}
.inputSet {
  height: 32px;
  width: 370px;
}
.login-container {
  display: 'flex';
  align-items: center;
  justify-content: center;
  height: 93vh;
  .login-logo {
    > div {
      display: flex;
      justify-content: center;
      > div {
        height: 90px;
        width: 184px;
        i {
          font-size: 90px;
        }
      }
    }
    svg {
      width: 2em;
      height: 1em;
    }
  }
  .rememberCheckbox {
    color: #999999;
    justify-self: flex-start;
  }
  .ant-input-group-addon {
    padding: 0px 0px;
    padding-left: 1px;
  }
}

.login-bottom-container {
  height: 7vh;
  min-height: 60px;
  .login-bottom-center-element {
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    margin-left: 64px !important;
    margin-right: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
}
.center-set {
  display: flex;
  justify-content: center;
}
.up-foot {
  width: 432px;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  .left-word {
    width: 180px;
  }
  .right-word {
    width: 250px;
    text-align: right;
  }
}
