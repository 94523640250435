@charset "UTF-8";
/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6;
  width: 150px; }
  .theme-normal-btn:hover, .theme-normal-btn:active, .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.normalModalCmp .ant-modal-header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px; }
  .normalModalCmp .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center; }

.normalModalCmp .ant-modal-content {
  border-radius: 16px; }

.normalModalCmp .ant-modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; }
  .normalModalCmp .ant-modal-body .ant-table {
    min-height: 300px; }

.normalModalCmp .ant-divider-inner-text {
  padding: 0; }

.normalModalCmp .modal-content-view {
  padding: 24px 40px;
  text-align: left; }

.normalModalCmp .ant-modal-header {
  border-bottom: 1px solid rgba(214, 214, 214, 0.64); }

.normalModalCmp .ant-modal-footer {
  padding: 24px;
  border-top: 1px solid rgba(214, 214, 214, 0.64); }

.normalModalCmp .ant-modal-close {
  right: 10px; }
  .normalModalCmp .ant-modal-close .anticon {
    padding: 4px;
    border: 1px solid #d6d6d6;
    border-radius: 20px; }
