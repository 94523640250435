.questiondrawer {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header {
    background-color: #F2F2F2;
  }

  .hospital-questionDetail {
    padding: 0;

    li {
      list-style-type: none;
      padding: 0 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(233, 233, 233, 1)
    }

    .questionDetaiTable {
      width: 100%;
      table-layout: fixed;

      tr {
        height: 40px;

      }
    }
  }
}