.pharmacyDetail {
  .logList {
    padding: 0px 10px 0px 24px;
    height: 64px;
    width: 100%;
    border-bottom: 1px rgba($color: #bbbbbb, $alpha: 0.45) solid;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5px;
  }

  .queryform-row {
    border-bottom: none !important;
  }
}

.lineContainer {
  display: flex;
  align-items: center;
  width: 100px;
  margin: 0px 10px 0 10px;
  height: 64px;
}