.right-menus {
  //   margin-right: 24px;
  display: flex;
  justify-content: flex-end;
  color: #00a1e6;
  cursor: pointer;
  margin-bottom: 2px;
}

.right-menus-active {
  display: flex;
  //   margin-right: 24px;
  justify-content: flex-end;
  background-color: #00a1e6;
  color: white;
  margin-bottom: 2px;
  cursor: pointer;
}
.menu-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #f2f2f2;
  color: #00a1e6;
}
.icon-container-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: white;
  background: #00a1e6;
}
