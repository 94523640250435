@mixin flex-dispalay {
  display: flex;
  justify-content: 'center';
  margin-top: 5px;
}
@mixin common-input {
  .ant-input-number-input {
    border: none;
  }

  .ant-input-number:hover {
    border: none;
    border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-input-number-focused {
    outline: none !important;
    box-shadow: none;
    border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  }
}
.form-item-container {
  @include flex-dispalay;
  @include common-input;
  .selected-item {
    margin-left: 8px;
    width: 240px;
    color: 'rgba(0, 0, 0, 0.64)';
    font-weight: 'bold';
  }
  align-items: center;
}

.form-TextArea-container {
  @include flex-dispalay;
  @include common-input;
  flex: 1;
}

.form-item-input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid rgba(214, 214, 214, 0.64);
  height: 32px;
}

.form-item-title {
  font-weight: 'bold';
  color: 'rgba(0,0,0,0.64)';
  font-weight: bold;
  width: 75px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
}

.titit {
  color: black;
  background: white;
}

.hint-form-item-title {
  display: flex;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.64);
  width: 125px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
}

.tooltip-custom {
  background: white;
  color: black !important;

  div {
    background-color: none;

    div {
      &:first-child {
        background: none;
        border-top-color: rgba(0, 161, 230, 0.32);
      }

      &:last-child {
        color: rgba(0, 0, 0, 0.64);
        background-color: white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 161, 230, 0.32);
      }
    }
  }
}
