.tableListForm {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-self: 'center';
}
.drawer-but-container {
  display: 'flex';
  justify-content: 'flex-end';
  border-top: 1px solid #e9e9e9;
  padding: 10px;
}
.drawer-order-detail-item {
  border-bottom: 1px dashed #e9e9e9;
  margin-bottom: 10px;
}
.trade-columns-scores {
  text-align: right !important;
}

.ant-tabs-tab-active {
  color: $main-color !important;
}
.ant-tabs-bar {
  margin: 0;
}
.recharge {
  margin-bottom: 10px;
  span:nth-child(1) {
    color: rgba(#000, 0.65);
    font-weight: bold;
  }
  span:nth-child(2) {
    color: rgba(#000, 0.65);
    margin-left: 10px;
  }
}
