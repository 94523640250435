$line-color: rgba(214, 214, 214, 1);
$theme-color: #00a1e6;
.proxai-manage-continer {
  .header-title {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 27px;
    color: rgba(0, 0, 0, 1);
  }
  .add-project {
    margin-top: 24px;
    margin-left: 24px;
    width: 302px;
    height: 104px;
    border: 1px dashed $line-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.64);
    border-radius: 16px;
    cursor: pointer;
  }
  .add-project-icon {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: $theme-color;
    margin-right: 7px;
  }
  .project-list-title {
    margin-left: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-top: 1px solid #e8e8e8;
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-left: 1px solid #e8e8e8;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-right: 1px solid #e8e8e8;
  }
  .project-row-action {
    color: $theme-color;
    display: flex;
    align-items: center;
  }
}
